import Form from '@snsw/react-component-library/build/Components/Form/Form';
import Row from '@snsw/react-component-library/build/layout/Grid/Row.styled';
import Col from '@snsw/react-component-library/build/layout/Grid/Col.styled';
import {colors} from '../CommonComponents/Typography/Typography.styled';
import React, {useContext} from 'react';
import {CampaignContext, campaignOptions} from './SendCampaignComponent';

const ConfirmNotificationDetailsComponent = ({notificationDescription, notificationCode, service,
    sendEmailEnabled, sendPushEnabled, showInInbox, sendSmsEnabled}) => {

    const {selectedCampaignOption, segmentCustomerCount, fileUploadCustomerCount} = useContext(CampaignContext);
    const customerCount = selectedCampaignOption === campaignOptions[1].value ? fileUploadCustomerCount : segmentCustomerCount;

    const channels = [];
    if (sendEmailEnabled) {channels.push('Email');}
    if (sendPushEnabled) {channels.push('Push');}
    if (sendSmsEnabled) {channels.push('SMS');}
    if (showInInbox) {channels.push('Inbox');}

    function getRow(name, details) {
        return <Row style={ {marginTop: '8px', marginBottom: '8px'} }>
            <Col span={ 3 }><b>{name}:</b></Col>
            <Col span={ 9 }>{details}</Col>
        </Row>;
    }

    return <Form style={ {background: `${colors.grey.iceBlue}`, padding: '16px', borderRadius: '5px'} }>
        {getRow('Name', notificationDescription)}
        {getRow('Code', notificationCode)}
        {getRow('Channels', channels.join(', '))}
        {getRow('Service', service?.description)}
        {getRow('Audience', 'Service NSW MyAccount customers')}
        {getRow('# Customers', customerCount)}
    </Form>;
};

export default ConfirmNotificationDetailsComponent;
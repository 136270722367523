import React, {useEffect, useRef, useState} from 'react';
import apiServices from '../../services/api-services';
import {
    Button,
    Col,
    Form,
    FormCheckboxGroup,
    FormInput,
    FormSelect,
    Heading,
    HorizontalRule,
    InPageAlert,
    Modal,
    Row,
    ComponentLoader
} from '@snsw/react-component-library';
import {useNavigate, useParams} from 'react-router-dom';
import {useBoolean} from '@snsw/react-component-library/build/utils';
import _, {sortBy} from 'lodash';
import {scrollToTop, useGlobalState, useUserRoleState} from '../GlobalStateComponent/GlobalState';
import {Buffer} from 'buffer';
import PreviewContentModal from '../PreviewContentModal/PreviewContentModal';
import {notificationChannel, portalConfigStatus} from '../../constants/applicationConstants';
import {
    ContentHeadingAndGuidance,
    encodeHTMLEntities,
    notificationChannelFormField,
    notificationCodeFormField,
    notificationDescriptionFormField,
    notificationTypes,
    serviceSelectFormField,
    sortOptionsByText,
    StatusNextInfoMsg,
    validateEmailSourceApplicationTUTD,
    validateTextField
} from '../CreateNotificationComponent/CreateNotificationComponent';
import TextLink from '@snsw/react-component-library/build/Components/TextLink/TextLink';
import {Card} from '@snsw/react-component-library/build/Components';
import {
    CustomContentContainer,
    CustomContentContainerForTabHeadings,
    FormFooterContainer,
    StyledTabPanel,
    TabHeading,
    TabHeadingsContainer,
    TabPanelContainer,
    TabsContainer
} from '../CreateNotificationComponent/CreateNotificationComponent.styled';
import {
    IconDelete,
    IconEmail,
    IconFeedback,
    IconMobile,
    IconNotifications
} from '@snsw/react-component-library/build/Icons/system';
import EmailContentFormComponent from '../EmailContentFormComponent/EmailContentFormComponent';
import PushContentFormComponent from '../PushContentFormComponent/PushContentFormComponent';
import InboxContentFormComponent from '../InboxContentFormComponent/InboxContentFormComponent';
import SmsContentFormComponent from '../SmsContentFormComponent/SmsContentFormComponent';
import PushNotificationToProd from '../PushNotificationToProdComponent/PushNotificationToProd';
import {extractNotificationChannels} from '../DashboardComponent/DashboardComponent';
import {StringParam, useQueryParam} from 'use-query-params';
import RadioButtonGroup from '../CommonComponents/RadioButtonGroup/RadioButtonGroup';

const UpdateNotificationComponent = () => {
    const [serviceOptions, setServiceOptions] = useState([]);
    const contentCheckbox = useRef();
    const {notificationCode} = useParams();
    const navigate = useNavigate();
    const [inProd] = useGlobalState();
    const {userRole} = useUserRoleState();
    const [from] = useQueryParam('from', StringParam);
    const defaultCheckboxOptions = [
        {
            isChecked: false, label: 'Email', value: 'sendEmailEnabled',
            clarify: notificationChannelFormField.email.clarify
        },
        {
            isChecked: false, label: 'Push', value: 'sendPushEnabled',
            clarify: notificationChannelFormField.push.clarify
        },
        {
            isChecked: false, label: 'MyServiceNSW Inbox', value: 'showInInbox',
            clarify: notificationChannelFormField.inbox.clarify
        },
        {
            isChecked: false, label: 'SMS', value: 'sendSmsEnabled',
            clarify: notificationChannelFormField.sms.clarify
        }
    ];
    const [isNotificationCodeUpdated, setIsNotificationCodeUpdated] = useState(false);
    const [templateOptions, setTemplateOptions] = useState({
        headers: [{value: '', text: '', content: ''}],
        footers: [{value: '', text: '', content: ''}],
        feedbacks: [{value: '', text: '', content: ''}],
        inboxFooters: [{value: '', text: '', content: ''}]
    });
    const [isNotificationConfigUpdated, setIsNotificationConfigUpdated] = useState(false);
    const [isTemplateConfigsUpdated, setIsTemplateConfigsUpdated] = useState(false);
    const [checkboxChannels, setCheckboxChannels] = useState(defaultCheckboxOptions);
    const [inputErrors, setInputErrors] = useState({
        notificationCode: {hasError: false, errorMessage: 'Invalid Notification Code'},
        service: {hasError: false, errorMessage: 'Please select a service'},
        description: {hasError: false, errorMessage: 'Please provide a Description'},
        notificationChannels: {hasError: false, errorMessage: 'You must select at least one channel'},
        emailSubject: {hasError: false, errorMessage: 'Provide an email subject line'},
        emailContent: {hasError: false, errorMessage: 'Enter email body content in html format'},
        emailFooterTemplate: {hasError: false, errorMessage: 'Select an email footer'},
        emailHeaderTemplate: {hasError: false, errorMessage: 'Please provide an Email Header Template'},
        inboxSubject: {hasError: false, errorMessage: 'Enter an inbox subject line'},
        inboxFooterTemplate: {hasError: false, errorMessage: 'Select an inbox footer'},
        inboxContent: {hasError: false, errorMessage: 'Enter inbox body content in html format'},
        pushSubject: {hasError: false, errorMessage: 'Please provide push subject'},
        pushContent: {hasError: false, errorMessage: 'Please provide push content'},
        smsContent: {hasError: false, errorMessage: 'Please provide SMS content'},
        emailSourceApplicationTUTD: {
            hasError: false,
            errorMessage: 'You must provide a source application for TUTD Feedback'
        },
        inboxSourceApplicationTUTD: {
            hasError: false,
            errorMessage: 'You must provide a source application for TUTD Feedback'
        }
    });
    const updateAlert = 'Your notification has been successfully updated and is now back in review by the notifications team. ' +
        'If your notification is an ongoing system-generated notification it has not been paused ' +
        'and the existing version is still being sent until the updated version has been reviewed.';
    const updateAlertWithConfigCacheInvalidationError = 'Your notification has been successfully updated and is now back in review ' +
        ` by the notifications team. Please contact notifications team as it is still being cached in ${process.env.REACT_APP_ENV_SRC}. ` +
        'If your notification is an ongoing system-generated notification it has not been paused ' +
        'and the existing version is still being sent until the updated version has been reviewed.';
    const [successStatus, setSuccessStatus] = useState({
        variant: 'success',
        title: 'Notification updated and re-submitted',
        description: updateAlert,
        visible: false
    });
    const [agencyConfig, setAgencyConfig] = useState({});
    const [navigateTo, setNavigateTo] = useState('');
    const refAgencyCode = useRef();

    const [notificationConfig, setNotificationConfig] = useState({
        service: {
            id: '',
            // Description used in email and inbox preview
            description: ''
        },
        description: '',
        notificationCode: '',
        showInInbox: false,
        sendSmsEnabled: false,
        sendEmailEnabled: false,
        sendPushEnabled: false,
        feedback: true,
        emailSourceApplicationTUTD: '',
        inboxSourceApplicationTUTD: '',
        notificationType: '',
        status: ''
    });
    const [templateConfigs, setTemplateConfigs] = useState({
        emailSubject: '',
        emailContent: '',
        emailHeaderTemplate: '',
        emailFooterTemplate: '',
        emailBodyTemplate: '',
        emailFeedbackTemplate: '',
        inboxSubject: '',
        inboxFooterTemplate: '',
        inboxContent: '',
        pushSubject: '',
        pushContent: '',
        smsContent: ''
    });
    const [initialTemplateConfigs, setInitialTemplateConfigs] = useState({
        emailSubject: '',
        emailContent: '',
        emailHeaderTemplate: '',
        emailFooterTemplate: '',
        emailBodyTemplate: '',
        emailFeedbackTemplate: '',
        inboxSubject: '',
        inboxFooterTemplate: '',
        inboxContent: '',
        pushSubject: '',
        pushContent: '',
        smsContent: ''
    });
    const [initialNotificationConfig, setInitialNotificationConfig] = useState({});
    const [showModal, open, close] = useBoolean(false);
    const inboxValidation = useRef(true);
    const emailContentValidation = useRef(true);
    const [loading, setLoading] = useState({isLoading: false, message: ''});
    const [loadingFooter, setLoadingFooter] = useState(true);
    const [existInProd, setExistInProd] = useState(false);
    const [showInboxPreviewModal, setShowInboxPreviewModal] = useState(false);
    const [showEmailPreviewModal, setShowEmailPreviewModal] = useState(false);
    const [showPushPreviewModal, setShowPushPreviewModal] = useState(false);
    const [showSmsPreviewModal, setShowSmsPreviewModal] = useState(false);
    const [showRemoveModal, openRemoveModal, closeRemoveModal] = useBoolean(false);
    const [showArchiveModal, openArchiveModelOpen, closeArchiveModel] = useBoolean(false);
    const [showSyncToNonProdModal, openSyncToNonProdModal, closeSyncToNonProdModal] = useBoolean(false);
    const [tabIndex, setTabIndex] = useState(0);
    const onShowSyncToNonProdModalOpen = (event) => {
        event.stopPropagation();
        openSyncToNonProdModal();
    };

    const onShowSyncToNonProdModalClose = () => {
        closeSyncToNonProdModal();
    };
    const defaultPendingChangesStatus = {
        notificationCode: '',
        pendingChanges: false,
        inProd: true,
        pendingEmailTemplateChanges: false,
        pendingInboxTemplateChanges: false,
        pendingPushTemplateChanges: false,
        pendingSmsTemplateChanges: false
    };
    const [pendingChangesStatus, setPendingChangesStatus] = useState(defaultPendingChangesStatus);

    useEffect(() => {
        scrollToTop();
        apiServices.searchNotificationCode(notificationCode, inProd).then(data => {
            setInitialNotificationConfig({
                ...data,
                emailSourceApplicationTUTD: data.emailSourceApplicationTUTD === null ? '' : data.emailSourceApplicationTUTD,
                inboxSourceApplicationTUTD: data.inboxSourceApplicationTUTD === null ? '' : data.inboxSourceApplicationTUTD,
                notificationType: data.notificationType.toLowerCase(), status: data.status
            });
            setNotificationConfig({
                ...data,
                showInInbox: data.showInInbox === null ? data.service.showInInbox : data.showInInbox,
                sendPushEnabled: data.sendPushEnabled === null ? data.service.sendPushEnabled : data.sendPushEnabled,
                sendEmailEnabled: data.sendEmailEnabled === null ? data.service.sendEmailEnabled : data.sendEmailEnabled,
                sendSmsEnabled: data.sendSmsEnabled === null ? data.service.sendSmsEnabled : data.sendSmsEnabled,
                emailSourceApplicationTUTD: data.emailSourceApplicationTUTD === null ? '' : data.emailSourceApplicationTUTD,
                inboxSourceApplicationTUTD: data.inboxSourceApplicationTUTD === null ? '' : data.inboxSourceApplicationTUTD,
                notificationType: data.notificationType.toLowerCase(),
                status: data.status
            });
            setAgencyConfig(data.service.agency);
            refAgencyCode.current = data.service.agency.agencyCode;

            setNavigateTo(from === 'dashboard' ? '/dashboard' : `/services/${data.service.agency.agencyCode}`);

            const checkedChannels = [...checkboxChannels];
            checkedChannels.forEach(item => {
                item.isChecked = data[item.value] === null ? data.service[item.value] : data[item.value];
                item.disabled = inProd;
            });
            setCheckboxChannels(checkedChannels);
            if (!inProd) {
                apiServices.compareNotification(notificationCode)
                    .then(data => {
                        setPendingChangesStatus(data);
                        setExistInProd(data.inProd);
                        setLoadingFooter(false);
                    })
                    .catch(() => {
                        setSuccessStatus({
                            variant: 'warning',
                            title: 'Network Error',
                            description: 'An Error occurred while trying to compare Non-Prod and prod configs.',
                            visible: true
                        });
                    });
            } else {
                setLoadingFooter(false);
            }
            apiServices.getTemplateWithContentsByNotificationId(data.id, 'EMAIL', inProd).then(templateWithContent => {
                const emailHeaderTemplate = templateWithContent.header;
                const emailFooterTemplate = templateWithContent.footer;
                const emailBodyTemplate = templateWithContent.body;
                const emailFeedbackTemplate = templateWithContent.feedback;

                const emailTemplateConfig = {};
                if (emailHeaderTemplate) {
                    emailTemplateConfig['emailHeaderTemplate'] = emailHeaderTemplate.id.toString();
                }

                if (emailFooterTemplate) {
                    emailTemplateConfig['emailFooterTemplate'] = emailFooterTemplate.id.toString();
                }

                if (emailBodyTemplate) {
                    emailTemplateConfig['emailBodyTemplate'] = emailBodyTemplate.id.toString();
                    emailTemplateConfig['emailContent'] = emailBodyTemplate ? decodeHTMLEntities(Buffer
                        .from(emailBodyTemplate.content, 'base64').toString('utf8')) : '';
                }

                if (emailFeedbackTemplate) {
                    emailTemplateConfig['emailFeedbackTemplate'] = emailFeedbackTemplate.id.toString();
                }
                if (templateWithContent.subject) {
                    emailTemplateConfig['emailSubject'] = Buffer.from(templateWithContent.subject, 'base64').toString('utf8');
                }

                setInitialTemplateConfigs(prevState => ({
                    ...prevState,
                    ...emailTemplateConfig
                }));
                setTemplateConfigs(prevState => ({
                    ...prevState,
                    ...emailTemplateConfig
                }));
            })
                .catch(error => console.log(error.message));
            apiServices.getTemplateWithContentsByNotificationId(data.id, 'INBOX', inProd).then(templateWithContent => {
                const inboxFooterTemplate = templateWithContent.footer;
                const inboxContent = templateWithContent.body;
                const inboxSubject = templateWithContent.subject;

                const inboxTemplateConfig = {};
                if (inboxSubject) {
                    inboxTemplateConfig['inboxSubject'] = Buffer.from(inboxSubject, 'base64').toString('utf8');
                }

                if (inboxFooterTemplate) {
                    inboxTemplateConfig['inboxFooterTemplate'] = inboxFooterTemplate.id.toString();
                }

                if (inboxContent) {
                    inboxTemplateConfig['inboxContent'] = decodeHTMLEntities(Buffer.from(inboxContent.content, 'base64')
                        .toString('utf8'));
                }

                setInitialTemplateConfigs(prevState => ({
                    ...prevState,
                    ...inboxTemplateConfig
                }));
                setTemplateConfigs(prevState => ({
                    ...prevState,
                    ...inboxTemplateConfig
                }));
            })
                .catch(error => console.log(error.message));

            apiServices.getTemplateWithContentsByNotificationId(data.id, 'PUSH', inProd).then(templateWithContent => {
                const pushSubject = templateWithContent.subject;
                const pushContent = templateWithContent.body.content;

                const pushTemplateConfig = {};
                if (pushSubject) {
                    pushTemplateConfig['pushSubject'] = Buffer.from(pushSubject, 'base64').toString('utf8');
                }

                if (pushContent) {
                    pushTemplateConfig['pushContent'] = Buffer.from(pushContent, 'base64').toString('utf8');
                }

                setInitialTemplateConfigs(prevState => ({
                    ...prevState,
                    ...pushTemplateConfig
                }));
                setTemplateConfigs(prevState => ({
                    ...prevState,
                    ...pushTemplateConfig
                }));
            })
                .catch(error => console.log(error.message));

            apiServices.getTemplateWithContentsByNotificationId(data.id, 'SMS', inProd).then(templateWithContent => {
                const smsContent = templateWithContent.body.content;
                const smsTemplateConfig = {};
                if (smsContent) {
                    smsTemplateConfig['smsContent'] = Buffer.from(smsContent, 'base64').toString('utf8');
                }

                setInitialTemplateConfigs(prevState => ({
                    ...prevState,
                    ...smsTemplateConfig
                }));
                setTemplateConfigs(prevState => ({
                    ...prevState,
                    ...smsTemplateConfig
                }));
            })
                .catch(error => console.log(error.message));
        })
            .catch(error => {
                console.log(error);
                const errorAlert = {
                    variant: inProd ? 'warning' : 'error',
                    title: inProd ? 'Page does not exist in Prod' : 'Network Error',
                    description: inProd ?
                        'This Notification does not exist in prod. Update in Non-prod and then promote to prod.' : error,
                    visible: true
                };
                traverse({state: errorAlert});

            });
        apiServices.fetchTemplateContentOptionsByTypes(inProd, ['HEADER', 'FOOTER', 'FEEDBACK', 'INBOX_FOOTER']).then(res => {
            const templates = {
                headers: sortBy(res.templates.headers, 'text'),
                footers: sortBy(res.templates.footers, 'text'),
                feedbacks: sortBy(res.templates.feedbacks, 'text'),
                inboxFooters: sortBy(res.templates.inboxFooters, 'text')
            };
            setTemplateOptions(templates);
        })
            .catch(error => console.log(error.message));
        apiServices.fetchTemplateContentOptionsByTypeAndDescription(inProd, 'CONTENT', 'MainContent').then(res => {
            // Assuming that there is only one content record with unique description 'MainContent'
            setInitialTemplateConfigs(prevState => ({
                ...prevState,
                emailBodyTemplate: res[0].value
            }));
            setTemplateConfigs(prevState => ({
                ...prevState,
                emailBodyTemplate: res[0].value
            }));
        })
            .catch(error => console.log(error.message));
        // eslint-disable-next-line
    }, [notificationCode, inProd]);

    useEffect(() => {
        if (_.isEqual(initialNotificationConfig, notificationConfig)) {
            setIsNotificationConfigUpdated(false);
        } else {
            setIsNotificationConfigUpdated(true);
        }// eslint-disable-next-line
    }, [notificationConfig]);

    useEffect(() => {
        if (_.isEqual(initialTemplateConfigs, templateConfigs)) {
            setIsTemplateConfigsUpdated(false);
        } else {
            setIsTemplateConfigsUpdated(true);
        }// eslint-disable-next-line
    }, [templateConfigs]);

    useEffect(() => {
        const options = [];
        apiServices.fetchServices(inProd).then(data => {
            data.forEach(service => {
                options.push({
                    text: service.description + ' (' + service.serviceCode + ')',
                    value: service.id,
                    serviceCode: service.serviceCode,
                    description: service.description,
                    serviceAgency: service.agency
                });
            });
            options.sort((a, b) => sortOptionsByText(a, b));
            setServiceOptions(options);
        })
            .catch(error => console.log(error.message));

        return() => setServiceOptions([]);
    }, [inProd]);

    const decodeHTMLEntities = (text) => {
        var textArea = document.createElement('textarea');
        textArea.innerHTML = text;
        return textArea.value;
    };

    const handleServiceSelect = (event) => {
        const selectedService = serviceOptions.find(service => service.value === Number(event.target.value));
        if (event.target.value && selectedService) {
            setNotificationConfig(prevState => ({
                ...prevState,
                // Assign the value to id, so that it matches the structure of notification object when it is initially retrieved
                service: {
                    id: selectedService?.value,
                    description: selectedService?.description
                }
            }));
            setAgencyConfig(selectedService.serviceAgency);
            setInputErrors(prevState => ({
                ...prevState,
                service: {hasError: false, errorMessage: serviceSelectFormField.errorMessage}
            }));
        } else {
            setInputErrors(prevState => ({
                ...prevState,
                service: {hasError: true, errorMessage: serviceSelectFormField.errorMessage}
            }));
        }
    };

    const handleChange = event => {
        const {name, value} = event.target;
        setNotificationConfig(prevState => ({
            ...prevState,
            [name]: value
        }));
        if (name === 'notificationCode') {
            if (value !== notificationCode) {
                setIsNotificationCodeUpdated(true);
            } else {
                setIsNotificationCodeUpdated(false);
            }
        }
        setInputErrors(prevState => ({
            ...prevState,
            [name]: {hasError: false, errorMessage: 'Error'}
        }));
    };

    const handleTemplateChange = event => {
        const {name, value} = event.target;
        setTemplateConfigs(prevState => ({
            ...prevState,
            [name]: value
        }));
        if (name === 'emailSourceApplicationTUTD') {
            setNotificationConfig(prevState => ({
                ...prevState,
                emailSourceApplicationTUTD: value
            }));
        }
        setInputErrors(prevState => ({
            ...prevState,
            [name]: {hasError: false, errorMessage: 'Error'}
        }));
        if (initialTemplateConfigs[name] !== value) {
            setIsTemplateConfigsUpdated(true);
        } else {
            setIsTemplateConfigsUpdated(false);
        }
        if (initialNotificationConfig[name] !== value) {
            setIsNotificationConfigUpdated(true);
        } else {
            setIsNotificationConfigUpdated(false);
        }
        if (name === 'inboxContent' && value.length > 0 && inboxValidation.current) {
            inboxValidation.current = false;
        }
        if (name === 'emailContent' && value.length > 0 && emailContentValidation.current) {
            emailContentValidation.current = false;
        }
    };

    function traverse(statusAlert) {
        let navigationState;
        if (from === 'dashboard' && statusAlert.variant === 'info') {
            navigationState = {};
        } else {
            navigationState = {
                state: {
                    alert: statusAlert
                }
            };
        }
        if (from !== 'dashboard') {
            navigationState.state.isExpanded = true;
            navigationState.state.serviceCode = notificationConfig.service?.serviceCode;
        }
        navigate(navigateTo, navigationState);
    }

    const discardChanges = () => {
        const statusAlert = {
            variant: 'info',
            title: 'Changes cancelled',
            description: 'Notification configuration was not updated',
            visible: true
        };
        traverse(statusAlert);

    };
    const onChannelChangeEvent = e => {
        setInputErrors(prevState => ({
            ...prevState,
            notificationChannels: {hasError: false, errorMessage: 'You must select at least one channel'}
        }));
        const val = e.target.value;
        const checkedChannels = [...checkboxChannels];
        checkedChannels.forEach(item => {
            if (item.value === val) {
                item.isChecked = !item.isChecked;
                setNotificationConfig(prevState => ({
                    ...prevState,
                    [val]: item.isChecked
                }));
                //Sets emailHeaderTemplate default value
                if (val === 'sendEmailEnabled' && item.isChecked && !initialTemplateConfigs.emailHeaderTemplate) {
                    setTemplateConfigs(prevState => ({
                        ...prevState,
                        emailHeaderTemplate: templateOptions.headers[0].value
                    }));
                }
                if (val === 'sendEmailEnabled' && !item.isChecked) {
                    setInputErrors(prevState => ({
                        ...prevState,
                        emailSubject: {hasError: false, errorMessage: 'Provide an email subject line'},
                        emailContent: {hasError: false, errorMessage: 'Enter email body content in html format'},
                        emailFooterTemplate: {hasError: false, errorMessage: 'Select an email footer'},
                        emailHeaderTemplate: {hasError: false, errorMessage: 'Please provide an Email Header Template'}
                    }));
                    setTemplateConfigs(prevState => ({
                        ...prevState,
                        emailHeaderTemplate: initialTemplateConfigs.emailHeaderTemplate
                    }));
                }
                if (val === 'showInInbox') {
                    if (!item.isChecked) {
                        setInputErrors(prevState => ({
                            ...prevState,
                            inboxSubject: {hasError: false, errorMessage: 'Please provide an inbox subject'},
                            inboxFooterTemplate: {hasError: false, errorMessage: 'Select an inbox footer'},
                            inboxContent: {hasError: false, errorMessage: 'Enter inbox body content in html format'}
                        }));
                    }
                    // If Inbox and PUSH are enabled, when Inbox is disabled, PUSH should be disabled as well.
                    // PUSH is index 1 in the Checkbox channel array.
                    if (checkedChannels[1].isChecked) {
                        checkedChannels[1].isChecked = false;
                        setNotificationConfig(prevState => ({
                            ...prevState,
                            sendPushEnabled: false
                        }));
                    }
                }
                if (val === 'sendPushEnabled') {
                    if (!item.isChecked) {
                        setInputErrors(prevState => ({
                            ...prevState,
                            pushSubject: {hasError: false, errorMessage: 'Please provide push subject'},
                            pushContent: {hasError: false, errorMessage: 'Please provide push content'}
                        }));
                    }
                    // If PUSH is enabled, then Inbox should be enabled as well.
                    // Inbox is index 2 in the Checkbox channel array.
                    if (item.isChecked && !checkedChannels[2].isChecked) {
                        checkedChannels[2].isChecked = true;
                        setNotificationConfig(prevState => ({
                            ...prevState,
                            showInInbox: true
                        }));
                    }
                }
                if (val === 'sendSmsEnabled' && !item.isChecked) {
                    setInputErrors(prevState => ({
                        ...prevState,
                        smsContent: {hasError: false, errorMessage: 'Please provide SMS content'}
                    }));
                }
            }
            // If currently selected tab corresponds to deselected channel, switch to tab 0
            if (tabIndex >= checkedChannels.filter(channel => channel.isChecked).length) {
                setTabIndex(0);
            }
        });
        setCheckboxChannels(checkedChannels);
    };

    const emailSourceApplicationTUTDValidation = async () => {
        return validateEmailSourceApplicationTUTD(notificationConfig.emailSourceApplicationTUTD,
            notificationConfig.sendEmailEnabled,
            templateConfigs.emailFeedbackTemplate,
            templateOptions,
            setInputErrors);
    };

    const notificationTextChangeHandle = (input, inputFieldType) => {
        return validateTextField(input, inputFieldType, setInputErrors);
    };

    const validateNotificationCode = async () => {
        const pattern = /^[A-Z_]{3,}$/;
        const code = notificationConfig.notificationCode;
        let valid;

        if (code.trim().length === 0) {
            setNotificationConfig(prevState => ({
                ...prevState,
                notificationCode: ''
            }));
            setInputErrors(prevState => ({
                ...prevState,
                notificationCode: {hasError: true, errorMessage: 'Enter a notification code'}
            }));
            valid = false;
        } else if (code.length > 50) {
            /**
             * customer_notification repo table's have limit of 50 characters for notification_code in notification_batch table
             * Hence applying this max length check
             */
            setInputErrors(prevState => ({
                ...prevState,
                notificationCode: {hasError: true, errorMessage: 'Max 50 characters allowed in a Notification code'}
            }));
            valid = false;
        } else {
            valid = pattern.test(code);
            const err = valid ? '' : 'Invalid Notification Code';
            setInputErrors(prevState => ({
                ...prevState,
                notificationCode: {hasError: !valid, errorMessage: err}
            }));
            if (valid && isNotificationCodeUpdated) {
                await apiServices.searchNotificationCode(code, inProd).then(() => {
                    setInputErrors(prevState => ({
                        ...prevState,
                        notificationCode: {hasError: true, errorMessage: 'Notification code already exists'}
                    }));
                    valid = false;
                })
                    .catch(() => {
                        /**
                         * Search Notification returns error if notification does not exists with notificationCode
                         * So set the error to empty string and return valid = true
                         */
                        const err = '';
                        setInputErrors(prevState => ({
                            ...prevState,
                            notificationCode: {hasError: !valid, errorMessage: err}
                        }));
                        valid = true;
                    });
            }
        }
        return valid;
    };
    const validateEmailRequest = (emailHeaderTemplate, emailContent, emailFooterTemplate) => {
        let validationResult = true;
        if (!notificationTextChangeHandle(templateConfigs.emailSubject, 'emailSubject')) {
            validationResult = false;
        }
        if (emailFooterTemplate.length === 0) {
            setInputErrors(prevState => ({
                ...prevState,
                emailFooterTemplate: {hasError: true, errorMessage: 'Select an email footer'}
            }));
            validationResult = false;
        }
        if (emailHeaderTemplate.length === 0) {
            setInputErrors(prevState => ({
                ...prevState,
                emailHeaderTemplate: {hasError: true, errorMessage: 'Select an email header'}
            }));
            validationResult = false;
        }
        if (emailContent.trim().length === 0) {
            setInputErrors(prevState => ({
                ...prevState,
                emailContent: {hasError: true, errorMessage: 'Enter email body content in html format'}
            }));
            validationResult = false;
        }
        if (emailContent.length > 0 && !emailContentValidation.current) {
            setInputErrors(prevState => ({
                ...prevState,
                emailContent: {
                    hasError: true,
                    errorMessage: 'Check HTML content before submitting'
                }
            }));
            validationResult = false;
        }
        return validationResult;
    };
    //Validates all inbox inputs before making post request.
    const validateInboxRequest = (inboxContent, inboxFooterTemplate) => {
        let validationResult = true;
        if (!notificationTextChangeHandle(templateConfigs.inboxSubject, 'inboxSubject')) {
            validationResult = false;
        }
        if (inboxContent.trim().length === 0) {
            setInputErrors(prevState => ({
                ...prevState,
                inboxContent: {hasError: true, errorMessage: 'Enter inbox body content in html format'}
            }));
            validationResult = false;
        }
        if (inboxContent.length > 0 && !inboxValidation.current) {
            setInputErrors(prevState => ({
                ...prevState,
                inboxContent: {
                    hasError: true,
                    errorMessage: 'Check HTML content before submitting'
                }
            }));
            validationResult = false;
        }
        if (inboxFooterTemplate.length === 0) {
            setInputErrors(prevState => ({
                ...prevState,
                inboxFooterTemplate: {hasError: true, errorMessage: 'Select an inbox footer'}
            }));
            validationResult = false;
        }
        return validationResult;
    };
    const validatePushRequest = () => {
        let validationResult = true;
        if (!notificationTextChangeHandle(templateConfigs.pushSubject, 'pushSubject')) {
            validationResult = false;
        }
        if (!notificationTextChangeHandle(templateConfigs.pushContent, 'pushContent')) {
            validationResult = false;
        }
        return validationResult;
    };

    // Select left-most content tab where there is an error showing
    const switchToTabOnValidation = (tabErrorsStatus) => {
        const tabs = tabErrorsStatus.filter(channel => channel.enabled);
        //console.log('tabs: ' + JSON.stringify(tabs));
        const tabIndex = tabs.findIndex(tab => tab.hasErrors);
        //console.log('switching to tab...' + tabIndex);
        if (tabIndex > -1) {
            setTabIndex(tabIndex);
        }
    };

    const validateRequest = async () => {
        let valid = pendingChangesStatus?.inProd ? true : await (validateNotificationCode()
            && notificationTextChangeHandle(notificationConfig.description, 'description'));
        valid = emailSourceApplicationTUTDValidation() ? valid : false;
        const {
            service,
            sendSmsEnabled,
            sendEmailEnabled,
            sendPushEnabled,
            showInInbox,
            emailSourceApplicationTUTD
        } = notificationConfig;
        const {
            emailHeaderTemplate,
            emailContent,
            emailFooterTemplate,
            emailFeedbackTemplate,
            inboxContent,
            inboxFooterTemplate
        } = templateConfigs;

        if (!service?.id) {
            setInputErrors(prevState => ({
                ...prevState,
                service: {hasError: true, errorMessage: 'Please select a service'}
            }));
            valid = false;
        }

        if (sendSmsEnabled === false && sendEmailEnabled === false && sendPushEnabled === false && showInInbox === false) {
            setInputErrors(prevState => ({
                ...prevState,
                notificationChannels: {hasError: true, errorMessage: 'You must select at least one channel'}
            }));
            valid = false;
        }
        const contentTabsErrorStatus = [
            {enabled: sendEmailEnabled, hasErrors: false},
            {enabled: sendPushEnabled, hasErrors: false},
            {enabled: showInInbox, hasErrors: false},
            {enabled: sendSmsEnabled, hasErrors: false}
        ];
        //Validates email template contents.
        if (sendEmailEnabled && !validateEmailRequest(emailHeaderTemplate, emailContent, emailFooterTemplate)) {
            valid = false;
            contentTabsErrorStatus[0].hasErrors = true;
        }
        // Validates inbox template contents.
        if (showInInbox && !validateInboxRequest(inboxContent, inboxFooterTemplate)) {
            valid = false;
            contentTabsErrorStatus[2].hasErrors = true;
        }
        //Validates push template contents.
        if (sendPushEnabled && !validatePushRequest()) {
            valid = false;
            contentTabsErrorStatus[1].hasErrors = true;
        }
        //Validates SMS template contents.
        if (sendSmsEnabled && !notificationTextChangeHandle(templateConfigs.smsContent, 'smsContent')) {
            valid = false;
            contentTabsErrorStatus[3].hasErrors = true;
        }
        switchToTabOnValidation(contentTabsErrorStatus);
        /**
         * This error should only apply to newly onboarded notifications for backwards compatability.
         * To check if the notification is new we check if the initial template had a separate footer before
         * checking if the footer is now empty with a full content.
         */

        if (!sendEmailEnabled && (emailFeedbackTemplate.length > 0 || emailSourceApplicationTUTD.trim().length > 0)) {
            setNotificationConfig(prevState => ({
                ...prevState,
                emailFeedbackTemplate: '',
                emailSourceApplicationTUTD: ''
            }));
        }
        return valid;
    };

    const updateNotification = async () => {
        setLoading({isLoading: true, message: 'Updating the Notification...'});
        validateRequest().then((valid) => {
            if (valid) {
                const promises = [];
                if (isNotificationConfigUpdated || isTemplateConfigsUpdated) {
                    /**
                     * If either notification config or template config updated, we need to set the status to IN_REVIEW
                     */
                    promises.push(apiServices.updateNotificationConfig({
                        ...notificationConfig,
                        status: portalConfigStatus.IN_REVIEW
                    }));
                }
                if (isTemplateConfigsUpdated) {
                    if ((templateConfigs.emailFooterTemplate !== initialTemplateConfigs.emailFooterTemplate) ||
                        (templateConfigs.emailHeaderTemplate !== initialTemplateConfigs.emailHeaderTemplate) ||
                        (templateConfigs.emailFeedbackTemplate !== initialTemplateConfigs.emailFeedbackTemplate) ||
                        (templateConfigs.emailSubject !== initialTemplateConfigs.emailSubject) ||
                        (templateConfigs.emailContent !== initialTemplateConfigs.emailContent)) {
                        promises.push(
                            apiServices.updateEmailTemplateConfigs(notificationConfig.notificationCode, templateConfigs)
                        );
                    }
                    if ((templateConfigs.inboxFooterTemplate !== initialTemplateConfigs.inboxFooterTemplate) ||
                        (templateConfigs.inboxContent !== initialTemplateConfigs.inboxContent) ||
                        (templateConfigs.inboxSubject !== initialTemplateConfigs.inboxSubject)) {
                        templateConfigs.inboxContent = encodeHTMLEntities(templateConfigs.inboxContent);
                        promises.push(
                            apiServices.updateInboxTemplateConfigs(notificationConfig.notificationCode, templateConfigs)
                        );
                    }
                    if ((templateConfigs.pushSubject !== initialTemplateConfigs.pushSubject) ||
                        (templateConfigs.pushContent !== initialTemplateConfigs.pushContent)) {
                        promises.push(
                            apiServices.updatePushTemplateConfigs(notificationConfig.notificationCode, templateConfigs)
                        );
                    }
                    if (templateConfigs.smsContent !== initialTemplateConfigs.smsContent) {
                        promises.push(
                            apiServices.updateSmsTemplateConfigs(notificationConfig.notificationCode, templateConfigs)
                        );
                    }
                }

                Promise.allSettled(promises)
                    .then(returnValues => {
                        let hasError = false;
                        const errors = [];
                        returnValues.forEach((response) => {
                            if (response.status === 'rejected') {
                                hasError = true;
                                errors.push(response.reason.response.data);
                            }
                        });
                        if (hasError) {
                            setLoading({isLoading: false, message: ''});
                            setSuccessStatus({
                                variant: 'error',
                                title: 'Network Error',
                                description: 'An error occurred updating one or all of the configs. ' +
                                    'Check the developer tools.',
                                visible: true
                            });
                        } else {
                            apiServices.invalidateConfigCache(notificationCode).then(data => {
                                notificationConfig.status = portalConfigStatus.IN_REVIEW;
                                const updatedAlert = {
                                    variant: 'success',
                                    title: 'Notification updated and re-submitted',
                                    description: updateAlert,
                                    visible: true
                                };
                                traverse(updatedAlert);
                            })
                                .catch(e => {
                                    notificationConfig.status = portalConfigStatus.IN_REVIEW;
                                    const updatedAlert = {
                                        variant: 'success',
                                        title: 'Notification updated and re-submitted',
                                        description: updateAlertWithConfigCacheInvalidationError,
                                        visible: true
                                    };
                                    traverse(updatedAlert);
                                });
                        }
                    });
            } else {
                setLoading({isLoading: false, message: ''});
                const errorElement = document.querySelectorAll('span[class^=\'FormError\']')[0]?.previousSibling;
                if (errorElement !== undefined) {
                    errorElement.scrollIntoView({
                        alignToTop: true,
                        behavior: 'smooth',
                        block: 'center'
                    });
                }
            }
        });
    };

    const onRemoveModalBackClicked = (event) => {
        event.stopPropagation();
        closeRemoveModal();
    };
    const onRemoveNotificationSettings = (event) => {
        setLoading({isLoading: true, message: 'Deleting the Notification...'});
        event.stopPropagation();
        closeRemoveModal();
        apiServices.removeNotification(notificationConfig.notificationCode).then(() => {
            setLoading({isLoading: false, message: ''});
            const deleteAlert = {
                variant: 'success',
                title: 'Done',
                description: 'Your notification has been deleted successfully',
                visible: true
            };
            traverse(deleteAlert);
        })
            .catch(e => {
                setLoading({isLoading: false, message: ''});
                setSuccessStatus({
                    variant: 'error',
                    title: 'Network Error',
                    description: `Error deleting notification ${notificationConfig.notificationCode}`,
                    visible: true
                });
            });
    };

    const ArchiveNotification = () => {
        setLoading({isLoading: true, message: 'Archiving the Notification...'});
        closeArchiveModel();
        apiServices.archiveNotification({...notificationConfig, status: portalConfigStatus.ARCHIVED}).then(() => {
            setLoading({isLoading: false, message: ''});
            const archiveAlert = {
                variant: 'success',
                title: 'Notification archived',
                visible: true
            };
            const navigationState = {state: {alert: archiveAlert}};
            navigate('/dashboard', navigationState);
        })
            .catch(e => {
                setLoading({isLoading: false, message: ''});
                const archiveAlert = ({
                    variant: 'error',
                    title: 'Error archiving notification. Contact engineering support',
                    visible: true
                });
                const navigationState = {state: {alert: archiveAlert}};
                navigate('/dashboard', navigationState);
            });
    };

    const onSyncToNonProdConfirmation = (event) => {
        event.stopPropagation();
        onShowSyncToNonProdModalClose();
        setLoading({isLoading: true, message: 'Promote to lower environments in progress...'});
        apiServices.syncNotificationToNonProd(notificationConfig.notificationCode).then(data => {
            const alerts = data.data.map(item => ({
                variant: item.status === 'SUCCESS' ? 'success' : 'error',
                title: item.status === 'SUCCESS' ? `Notification was successfully Synced to ${item.env}` :
                    `Notification did not sync to ${item.env} due to ${item.errorMsg} `,
                description: ''
            }));

            setLoading({isLoading: false, message: ''});
            const navigationState = {
                state: {
                    serviceCode: notificationConfig.service?.serviceCode,
                    isExpanded: true,
                    syncToLowerStatus: {
                        alerts: alerts,
                        visible: true
                    }
                }
            };
            navigate(navigateTo, navigationState);
        })
            .catch(e => {
                setLoading({isLoading: false, message: ''});
                setSuccessStatus({
                    variant: 'error',
                    title: 'Internal Server Error',
                    description: 'An error occurred syncing notification to lower environments. ' +
                        'Check the developer tools.',
                    visible: true
                });
            });
    };

    const onSyncToNonProdModelBackClicked = (event) => {
        event.stopPropagation();
        onShowSyncToNonProdModalClose();
    };

    const syncToNonProdModalButtons = [
        {text: 'Sync to lower', id: 'syncChangesToNonProdBtn', onClick: onSyncToNonProdConfirmation},
        {text: 'Back', id: 'promoteChangesBackBtn', onClick: onSyncToNonProdModelBackClicked}
    ];

    const channelSelectionChanged = () => {
        return (notificationConfig.sendEmailEnabled !== initialNotificationConfig.sendEmailEnabled ||
            notificationConfig.sendSmsEnabled !== initialNotificationConfig.sendSmsEnabled ||
            notificationConfig.sendPushEnabled !== initialNotificationConfig.sendPushEnabled ||
            notificationConfig.showInInbox !== initialNotificationConfig.showInInbox);
    };

    return (
        <div style={ {whiteSpace: 'break-spaces', paddingTop: '3.5rem'} }>
            {loading.isLoading && <ComponentLoader fullPage label={ loading.message }/>}
            <CustomContentContainer>
                {(!isNotificationConfigUpdated && !isTemplateConfigsUpdated) ?
                    <Button style={ {width: '62px'} } variant='back' id='backBtn' onClick={ discardChanges }>Back</Button> :
                    <>
                        <Button style={ {width: '62px'} } variant='back' id='backBtn' onClick={ open }>Back</Button>
                        {showModal && (
                            <Modal
                                title='Are you sure you want to discard your unsaved changes?'
                                description='By going back you will loose all the progress on
                                    this form and notification will not be updated.'
                                buttons={ [
                                    {text: 'Discard', onClick: discardChanges},
                                    {text: 'Cancel', onClick: () => close()}
                                ] }
                            />
                        )}
                    </>
                }
                <Heading
                    className='page-title'
                    style={ {height: 'fit-content', margin: '18px 0 0 0'} }
                    level={ 1 }
                >Edit notification</Heading>
                <Form data-test='notification-config-form'>
                    <Row>
                        <Col span={ 9 }>
                            <FormInput
                                label={ notificationDescriptionFormField.label }
                                name={ notificationDescriptionFormField.name }
                                disabled={ inProd }
                                errorMessage={ inputErrors.description.errorMessage }
                                hasError={ inputErrors.description.hasError }
                                helpMessage={ notificationDescriptionFormField.helpMessage }
                                margin={ {top: 24} }
                                onBlur={ () => notificationTextChangeHandle(notificationConfig.description, 'description') }
                                onChange={ handleChange }
                                value={ notificationConfig.description }
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col span={ 9 }
                            style={ {paddingTop: '10px'} }>
                            <RadioButtonGroup
                                data-testid={ 'notification-types' }
                                label={ 'Notification Type' }
                                disabled={ true }
                                options={ notificationTypes }
                                defaultOption={ notificationConfig.notificationType }
                                selectedValue={ notificationConfig.notificationType }
                            />

                        </Col>
                    </Row>
                    <Row>
                        <Col span={ 9 }>
                            <FormInput
                                label={ notificationCodeFormField.label }
                                name={ notificationCodeFormField.name }
                                disabled={ (pendingChangesStatus != null ? pendingChangesStatus.inProd : true) || inProd }
                                hasError={ inputErrors.notificationCode.hasError }
                                helpMessage={ notificationCodeFormField.helpMessage }
                                errorMessage={ inputErrors.notificationCode.errorMessage }
                                margin={ {top: 24} }
                                onChange={ handleChange }
                                value={ notificationConfig.notificationCode }
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col span={ 9 } style={ {display: 'grid', gridTemplateColumns: '1fr 1fr', columnGap: '3%'} }>
                            <FormSelect
                                label={ serviceSelectFormField.label }
                                name={ 'selectService' }
                                style={ {width: '206%', maxWidth: '206%'} }
                                errorMessage={ inputErrors.service.errorMessage }
                                hasError={ inputErrors.service.hasError }
                                options={ serviceOptions }
                                margin={ {top: 16} }
                                onChange={ handleServiceSelect }
                                value={ notificationConfig?.service?.id }
                                disabled={ (pendingChangesStatus != null ? pendingChangesStatus.inProd : true) || inProd }
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col span={ 9 }>
                            <FormCheckboxGroup
                                dataTest='notification-channels'
                                id='notification-channels'
                                name={ notificationChannelFormField.name }
                                legend={ notificationChannelFormField.legend }
                                helpMessage={
                                    <TextLink
                                        target={ '_blank' }
                                        href={ `${process.env.REACT_APP_SERVICE_NSW_WIKI_URL}/11926472274/Delivery+channels` }>
                                        How to select the right channels
                                    </TextLink>
                                }
                                errorMessage={ inputErrors.notificationChannels.errorMessage }
                                hasError={ inputErrors.notificationChannels.hasError }
                                options={ checkboxChannels }
                                onChange={ onChannelChangeEvent }
                                margin={ {top: 24} }
                                ref={ contentCheckbox }
                            />
                        </Col>
                    </Row>
                    <ContentHeadingAndGuidance/>
                </Form>
            </CustomContentContainer>
            {(notificationConfig.sendSmsEnabled || notificationConfig.sendEmailEnabled ||
                    notificationConfig.sendPushEnabled || notificationConfig.showInInbox) &&
                <TabsContainer selectedTabClassName='is-selected'
                    selectedIndex={ tabIndex } onSelect={ (index) => setTabIndex(index) }>
                    <CustomContentContainerForTabHeadings>
                        <TabHeadingsContainer>
                            {notificationConfig.sendEmailEnabled &&
                                <TabHeading data-testid={ 'emailTab' }><IconEmail/>Email</TabHeading>
                            }
                            {notificationConfig.sendPushEnabled &&
                                <TabHeading data-testid={ 'pushTab' }><IconMobile/>Push</TabHeading>
                            }
                            {notificationConfig.showInInbox &&
                                <TabHeading data-testid={ 'inboxTab' }><IconNotifications/>Inbox</TabHeading>
                            }
                            {notificationConfig.sendSmsEnabled &&
                                <TabHeading data-testid={ 'smsTab' }><IconFeedback/>SMS</TabHeading>
                            }
                        </TabHeadingsContainer>
                    </CustomContentContainerForTabHeadings>
                    <TabPanelContainer>
                        {notificationConfig.sendEmailEnabled &&
                            <StyledTabPanel>
                                <CustomContentContainer>
                                    <EmailContentFormComponent
                                        id='emailTemplateInputs'
                                        templateOptions={ templateOptions }
                                        notificationConfig={ {...templateConfigs, ...notificationConfig} }
                                        setNotificationConfig={ setTemplateConfigs }
                                        handleChange={ handleTemplateChange }
                                        setShowEmailPreviewModal={ setShowEmailPreviewModal }
                                        emailContentValidated={ emailContentValidation }
                                        inputErrors={ inputErrors }
                                        setInputErrors={ setInputErrors }
                                        inProd={ inProd }
                                    />
                                </CustomContentContainer>
                            </StyledTabPanel>
                        }
                        {notificationConfig.sendPushEnabled &&
                            <StyledTabPanel>
                                <CustomContentContainer>
                                    <PushContentFormComponent
                                        id='pushTemplateInputs'
                                        inputErrors={ inputErrors }
                                        setInputErrors={ setInputErrors }
                                        handleChange={ handleTemplateChange }
                                        pushSubject={ templateConfigs.pushSubject }
                                        pushContent={ templateConfigs.pushContent }
                                        setShowPushPreviewModal={ setShowPushPreviewModal }
                                        inProd={ inProd }
                                    />
                                </CustomContentContainer>
                            </StyledTabPanel>
                        }
                        {notificationConfig.showInInbox &&
                            <StyledTabPanel>
                                <CustomContentContainer>
                                    <InboxContentFormComponent
                                        id='inboxTemplateInputs'
                                        inputErrors={ inputErrors }
                                        setInputErrors={ setInputErrors }
                                        handleChange={ handleTemplateChange }
                                        notificationConfig={ templateConfigs }
                                        setNotificationConfig={ setTemplateConfigs }
                                        inboxFooterOptions={ templateOptions.inboxFooters }
                                        inboxContentValidation={ inboxValidation }
                                        setShowInboxPreviewModal={ setShowInboxPreviewModal }
                                        inProd={ inProd }
                                    />
                                </CustomContentContainer>
                            </StyledTabPanel>
                        }
                        {notificationConfig.sendSmsEnabled &&
                            <StyledTabPanel>
                                <CustomContentContainer>
                                    <SmsContentFormComponent
                                        inputErrors={ inputErrors }
                                        setInputErrors={ setInputErrors }
                                        handleChange={ handleTemplateChange }
                                        smsContent={ templateConfigs.smsContent }
                                        setShowSmsPreviewModal={ setShowSmsPreviewModal }
                                        inProd={ inProd }
                                    />
                                </CustomContentContainer>
                            </StyledTabPanel>
                        }
                    </TabPanelContainer>
                    <FormFooterContainer>
                        <CustomContentContainer>
                            <HorizontalRule style={ {
                                margin: '32px 0 0 0'
                            } }/>
                            <div style={ {marginTop: '32px', display: 'flex', flexDirection: 'column', rowGap: '32px'} }>
                                <div style={
                                    {
                                        display: 'flex',
                                        flexDirection: 'row',
                                        columnGap: '25px'
                                    }
                                }>
                                    {!inProd && <Button
                                        onClick={ updateNotification }
                                        disabled={ !isNotificationConfigUpdated && !isTemplateConfigsUpdated }
                                        variant='primary'
                                        id='updateNotificationBtn'
                                    >Save and submit</Button>}

                                    {!inProd && userRole.permissions.NOTIFICATION.includes('SYNC_TO_TEST_ENVS') &&
                                        <div>
                                            <Button
                                                data-testid='showSyncToNonProdModalButton'
                                                variant='secondary'
                                                disabled={ isNotificationConfigUpdated || isTemplateConfigsUpdated }
                                                onClick={ onShowSyncToNonProdModalOpen }
                                            >
                                                Sync to lower
                                            </Button>
                                        </div>
                                    }
                                    {!inProd
                                        && userRole.permissions.NOTIFICATION.includes('PROMOTE')
                                        && pendingChangesStatus.pendingChanges &&
                                        notificationConfig.status === portalConfigStatus.IN_REVIEW &&
                                        <PushNotificationToProd
                                            notification={ notificationConfig }
                                            data-testid={ 'pushNotificationToProdBtn' }
                                            serviceCode={ notificationConfig.service?.serviceCode }
                                            agencyConfig={ agencyConfig }
                                            setAlert={ setSuccessStatus }
                                            pendingTemplateChanges={ isTemplateConfigsUpdated || channelSelectionChanged() }
                                            traverse={ traverse }
                                        />
                                    }
                                    <ComponentLoader active={ loadingFooter }/>
                                    <>
                                        {!inProd && userRole.permissions.NOTIFICATION.includes('DELETE') && !existInProd &&
                                        <Button
                                            id={ `removeNotificationSettingsBtn-${notificationConfig.notificationCode}` }
                                            data-testid={ `removeNotificationSettingsBtn-${notificationConfig.notificationCode}` }
                                            variant='link'
                                            onClick={ openRemoveModal }
                                            style={ {padding: '0px,5px'} }
                                        >
                                            Delete notification
                                        </Button>
                                        }
                                        {!inProd && userRole.permissions.NOTIFICATION.includes('ARCHIVE') && existInProd
                                        && notificationConfig.status !== 'ARCHIVED' &&
                                        <Button
                                            id={ `archiveNotificationSettingsBtn-${notificationConfig.notificationCode}` }
                                            data-testid={ `archiveNotificationSettingsBtn-${notificationConfig.notificationCode}` }
                                            variant='link'
                                            onClick={ openArchiveModelOpen }
                                            style={ {padding: '0px,5px'} }
                                        >
                                            <IconDelete color='secondaryBlue'
                                                style={ {marginRight: '9px', width: '14px', height: '14px'} }/>
                                            Archive
                                        </Button>
                                        }
                                    </>
                                </div>
                            </div>
                            <StatusNextInfoMsg/>
                            {successStatus.visible ? <Row>
                                <Col span={ 9 }>
                                    <InPageAlert id='notification-status' variant={ successStatus.variant }
                                        title={ successStatus.title }>
                                        <p data-test='notStatusDesc'>{successStatus.description}</p>
                                    </InPageAlert>
                                </Col>
                            </Row> : null}
                        </CustomContentContainer>
                    </FormFooterContainer>
                </TabsContainer>
            }
            {showSyncToNonProdModal && (
                <Modal
                    title='Sync to all lower environments'
                    description={ `The following notification will be promoted to all lower non-prod 
                                environments including ${process.env.REACT_APP_SYNC_ENVS}` }
                    buttons={ syncToNonProdModalButtons }
                >
                    <Card style={ {marginBottom: 20} }>
                        <table>
                            <tbody>
                                <tr>
                                    <td style={ {fontWeight: 'bold'} }>Name:</td>
                                    <td>{notificationConfig.description}</td>
                                </tr>
                                <tr>
                                    <td style={ {fontWeight: 'bold'} }>Code:</td>
                                    <td>{notificationConfig.notificationCode}</td>
                                </tr>
                                <tr>
                                    <td style={ {fontWeight: 'bold'} }>Channels:</td>
                                    <td>{extractNotificationChannels(notificationConfig)}</td>
                                </tr>
                                <tr>
                                    <td style={ {fontWeight: 'bold'} }>Type:</td>
                                    <td>{notificationConfig.notificationType}</td>
                                </tr>
                                <tr>
                                    <td style={ {fontWeight: 'bold'} }>Service:</td>
                                    <td>{notificationConfig.service ? notificationConfig.service.serviceCode : ''}</td>
                                </tr>
                            </tbody>
                        </table>
                    </Card>
                </Modal>
            )}

            {showInboxPreviewModal &&
                <PreviewContentModal
                    channel={ notificationChannel.INBOX }
                    inboxPreviewSubstitutes={ {
                        inboxSubject: templateConfigs.inboxSubject,
                        inboxContent: templateConfigs.inboxContent,
                        inboxFooter: templateConfigs.inboxFooterTemplate.length > 0 ?
                            templateOptions.inboxFooters.find((footer) =>
                                footer.value === templateConfigs.inboxFooterTemplate).content : ''
                    } }
                    sender={ agencyConfig.description }
                    service={ notificationConfig.service.description }
                    notificationCode={ notificationCode }
                    inProd={ inProd }
                    setShowPreviewModal={ setShowInboxPreviewModal }
                />
            }
            {showEmailPreviewModal &&
                <PreviewContentModal
                    channel={ notificationChannel.EMAIL }
                    emailPreviewSubstitutes={ {
                        emailContent: templateConfigs.emailContent,
                        emailFooter: templateOptions.footers.find((footer) =>
                            footer.value === templateConfigs.emailFooterTemplate).content,
                        emailHeader: templateOptions.headers.find((header) =>
                            header.value === templateConfigs.emailHeaderTemplate).content,
                        emailFeedback: templateConfigs.emailFeedbackTemplate.length > 0 ?
                            templateOptions.feedbacks.find((feedback) =>
                                feedback.value === templateConfigs.emailFeedbackTemplate).content : '',
                        emailSubject: templateConfigs.emailSubject
                    } }
                    sender={ agencyConfig.description }
                    service={ notificationConfig.service.description }
                    notificationCode={ notificationCode }
                    inProd={ inProd }
                    setShowPreviewModal={ setShowEmailPreviewModal }
                />
            }
            {showPushPreviewModal &&
                <PreviewContentModal
                    channel={ notificationChannel.PUSH }
                    pushPreviewSubstitutes={ {
                        pushContent: templateConfigs.pushContent,
                        pushSubject: templateConfigs.pushSubject
                    } }
                    sender={ agencyConfig.description }
                    notificationCode={ notificationCode }
                    inProd={ inProd }
                    setShowPreviewModal={ setShowPushPreviewModal }
                />
            }
            {showSmsPreviewModal &&
                <PreviewContentModal
                    channel={ notificationChannel.SMS }
                    smsContent={ templateConfigs.smsContent }
                    sender={ agencyConfig.description }
                    notificationCode={ notificationCode }
                    inProd={ inProd }
                    setShowPreviewModal={ setShowSmsPreviewModal }
                />
            }
            {showRemoveModal && (
                <Modal
                    title='Delete notification settings'
                    description={ existInProd ? `Notification settings for '${notificationConfig.description}' can not be deleted. ` +
                        'It is already promoted to Production' : `Notification settings for '${notificationConfig.description}' will ` +
                        'be permanently deleted. Do you want to proceed.' }
                    buttons={ existInProd ? [
                        {text: 'Back', onClick: onRemoveModalBackClicked}
                    ] :
                        [
                            {text: 'Delete', onClick: onRemoveNotificationSettings},
                            {text: 'Back', onClick: onRemoveModalBackClicked}
                        ]
                    }
                />
            )}
            {showArchiveModal && (
                <Modal
                    title='Are you sure you want to archive notification?'
                    description={ 'It will be removed from the dashboard and no longer sent.' }
                    buttons={ [
                        {text: 'Cancel', onClick: () => closeArchiveModel()},
                        {text: 'Archive', onClick: () => ArchiveNotification()}
                    ] }>
                    <Form style={ {background: '#F4F7F9', padding: '16px', borderRadius: '5px'} }>
                        <Row style={ {marginTop: '8px', marginBottom: '8px'} }>
                            <Col span={ 2 }>
                                <b>Name:</b>
                            </Col>
                            <Col span={ 10 }>
                                {notificationConfig.description}
                            </Col>
                        </Row>
                        <Row style={ {marginTop: '8px', marginBottom: '8px'} }>
                            <Col span={ 2 }>
                                <b>Code:</b>
                            </Col>
                            <Col span={ 10 }>
                                {notificationConfig.notificationCode}
                            </Col>
                        </Row>
                        <Row style={ {marginTop: '8px', marginBottom: '8px'} }>
                            <Col span={ 2 }>
                                <b>Channels:</b>
                            </Col>
                            <Col span={ 10 }>
                                {notificationConfig.service.sendEmailEnabled ? 'Email' : ''},&nbsp;
                                {notificationConfig.service.sendPushEnabled ? 'Push' : ''},&nbsp;
                                {notificationConfig.service.showInInbox ? 'Inbox' : ''},&nbsp;
                                {notificationConfig.service.sendSmsEnabled ? 'SMS' : ''}
                            </Col>
                        </Row>
                        <Row style={ {marginTop: '8px', marginBottom: '8px'} }>
                            <Col span={ 2 }>
                                <b>Service:</b>
                            </Col>
                            <Col span={ 10 }>
                                {notificationConfig.service.description}
                            </Col>
                        </Row>
                    </Form>

                </Modal>
            )}

        </div>

    );
};

export default UpdateNotificationComponent;


import styled from 'styled-components';
import {colors, fontSize, globalFont} from '../CommonComponents/Typography/Typography.styled';

export const SidebarContainer = styled.div`
    width: 264px;
    background-color: ${colors.white};
    height: 100%;
    position: fixed;
    font-family: ${globalFont};
    z-index: 3;
    border-right: 'solid 1px ${colors.grey.geyser}';
`;

export const LogoAndTitleWrapper = styled.div`
top: 0;
    background: ${colors.white};
    padding-bottom: 0;
`;

export const SidebarTitle = styled.div`
    padding-left: 30px;
    padding-right: 34px;
    color: ${colors.brand.snswPrimaryBlue};
    font-feature-settings: 'clig' off, 'liga' off;
    font-size: 18px;
    font-weight: 700;
    line-height: 26px;
`;

export const DividerLine = styled.img`
    display: block;
    margin: auto;
    opacity: ${({hidden}) => hidden ? 0 : 1};
    transition: opacity 0.3s ease;
    padding-top: 20px; // You can also use margin if you want to change the spacing
    z-index: -1;
`;

export const SidebarItemWrapper = styled.div`
    overflow: auto;
    height: ${({prodSelector}) => (prodSelector ? 'calc(-247px + 100vh)' : 'calc(-191px + 100vh)')};
`;

export const ItemList = styled('ul')`
    list-style-type: none;
    margin: 18px 0;
    padding-left: 20px;
    color: ${colors.grey.shuttle};
    font-feature-settings: 'clig' off, 'liga' off;
    font-size: ${fontSize.h6};
`;

export const SidebarItemContainer = styled('li')`
    display: flex;
    align-items: center;
    padding: 10px 10px 5px 6px;
    cursor: pointer;
    border-radius: 5px;

    margin-bottom: unset;

    a,
    span {
        color: ${({
        isSelected,
        isHovered
    }) => (isSelected || isHovered ? colors.brand.snswPrimaryBlue : colors.grey.shuttle)};
        font-weight: ${({isSelected}) => (isSelected ? '600 !important' : 'normal')};
    }

    img {
        margin-left: unset;
        margin-right: 15px;
        width: 18px;
    }
`;

export const Chevron = styled.img`
    margin-left: auto !important;
    margin-right: 15px;
    width: 11px !important;
`;
export const SidebarChildren = styled(ItemList)`
    font-size: 14px;
    margin-top: 0;
    position: relative;
    left: 8px;
    width: 210px; /*Prevents children going over edge of sidebar */
`;
export const UserItemContainer = styled.div`
    position: sticky;
    bottom: 20px;
    left: 28px;
    width: auto;
    height: 50px;
`;

// The ListWrapper and ListItemWrapper here to ensure proper nesting of elements within a list (<ul> or <ol>)
// according to WCAG 1.3.1 guidelines.
// Specifically, <ul> elements should only contain valid list children: <li> elements.
// So nested lists, ie <ul> --> <ul> --> <li> should be converted into <ul> --> <li> --> <ul> --> <li>
export const ListWrapper = styled.ul`
    margin: 0;
    padding: 0;
    list-style-type: none;
`;

export const ListItemWrapper = styled.li`
    margin: 0;
    padding: 0;
    list-style: none;
`;

export const UserItemList = styled(ItemList)`
    position: relative;
    right: -24px;
    bottom: 116px;
    padding-bottom: 0;
    background: ${colors.white};
    border-radius: 6px;
    width: 224px;
    border: '0.5px solid ${colors.grey.geyser}';
    height: 48px;
    box-shadow: 0 2px 8px 0 ${colors.brand.snswInfoBlueLight};
    text-decoration: underline;
`;

export const UserIcon = styled.img`
    width: 32px !important;
`;
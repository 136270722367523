import React, {useState} from 'react';
import {Link} from 'react-router-dom';
import {Chevron, SidebarItemContainer, UserIcon} from './SidebarMenu.styled';

const SidebarItem = ({item, handleClick, isExpanded, isSelected}) => {
    const [isHovered, setIsHovered] = useState(false);

    const handleKeyDown = (event) => {
        if (event && (event.key === 'Enter' || event.key === ' ')) {
            handleClick(item);
        }
    };

    return (
        item.visible !== false &&
        <SidebarItemContainer onClick={ () => handleClick(item) }
            onMouseEnter={ () => setIsHovered(true) }
            onMouseLeave={ () => setIsHovered(false) }
            style={ {
                display: 'flex',
                alignItems: 'center'
            } }
            isSelected={ isSelected }
            isExpanded={ isExpanded }
            isHovered={ isHovered }
        >
            {/*Checks if the item has an icon to set in src*/}
            {item.isUserItem ?
                <UserIcon src={ item.icon } alt={ 'User icon' } role={ 'img' } aria-hidden={ true }/>
                : item.icon &&
                // not all items with an icon also have a bold or blue icon, so this is checked in src
                <img src={
                    isSelected && item.iconBlueBold ?
                        item.iconBlueBold :
                        isHovered && item.iconBlue ?
                            item.iconBlue : item.icon }
                alt={ `Icon ${item.label}` } aria-hidden={ true }
                style={ {marginRight: '10px'} }/>} {/* Add space between icon and title */}

            {/* If the item is a link to an external source we render <a> tags instead of <Link> tags */}
            {/* If the item is an expandable without a link we render <span> tags */}
            {/* Using the <Link> tags if it does not go to an internal route possibly resets the state, causing errors */}
            {item.isExternalSource ? (
                <a href={ item.target } rel='noreferrer' target='_blank'>{item.label}</a>
            ) : item.isNotLink ? (
                <span>{item.label}</span>
            ) : (<Link to={ item.link }>{item.label}</Link>)
            }
            {item.children && isExpanded && (
                <Chevron src='../../assets/icons/ChevronUp.svg'
                    tabIndex='0'
                    onKeyDown={ handleKeyDown }
                    alt={ `Expand ${item.label} menu` }/>
            )}
            {item.children && !isExpanded && (
                <Chevron src='../../assets/icons/ChevronDown.svg'
                    tabIndex='0'
                    onKeyDown={ handleKeyDown }
                    alt={ `Collapse ${item.label} menu` }/>
            )}
        </SidebarItemContainer>
    );
};
export default SidebarItem;
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import * as serviceWorker from './serviceWorker';
import {ThemeProvider} from 'styled-components';
import {GlobalStyle, theme} from '@snsw/react-component-library';
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import {OktaAuth, toRelativeUrl} from '@okta/okta-auth-js';
import {LoginCallback, Security} from '@okta/okta-react';
// import authenticateServices from './services/authenticate-services';
import LoginComponent from './Components/LoginComponent/LoginComponent';
import ProtectedComponent from './Components/ProtectedComponent/ProtectedComponent';
import CreateAgencyComponent from './Components/CreateAgencyComponent/CreateAgencyComponent';
import ServiceListComponent from './Components/ServiceListComponent/ServiceListComponent';
import UpdateAgencyComponent from './Components/UpdateAgencyComponent/UpdateAgencyComponent';
import CreateServiceComponent from './Components/CreateServiceComponent/CreateServiceComponent';
import UpdateServiceComponent from './Components/UpdateServiceComponent/UpdateServiceComponent';
import UpdateNotificationComponent from './Components/UpdateNotificationComponent/UpdateNotificationComponent';
import CreateNotificationComponent from './Components/CreateNotificationComponent/CreateNotificationComponent';
import {GlobalState} from './Components/GlobalStateComponent/GlobalState';
import AgencyListComponent from './Components/AgencyListComponent/AgencyListComponent';
import SendNotificationComponent from './Components/SendNotificationComponent/SendNotificationComponent';
import PreferenceTileListComponent from './Components/PreferenceTileListComponent/PreferenceTileListComponent';
import CreatePreferenceTileComponent from './Components/CreatePreferenceTileComponent/CreatePreferenceTileComponent';
import UpdatePreferenceTileComponent from './Components/UpdatePreferenceTileComponent/UpdatePreferenceTileComponent';
import AccessRequestComponent from './Components/AccessRequestComponent/AccessRequestComponent';
import GlobalNavWrapper from './Components/GlobalNavWrapper/GlobalNavWrapper';
import OutageBannerComponent from './Components/OutageBannerComponent/OutageBannerComponent';
import UserManagementComponent from './Components/UserManagementComponent/UserManagementComponent';
import CreateUserComponent from './Components/CreateUserComponent/CreateUserComponent';
import UpdateUserComponent from './Components/UpdateUserComponent/UpdateUserComponent';
import AdminComponent from './Components/AdminComponent/AdminComponent';
import DashboardComponent from './Components/DashboardComponent/DashboardComponent';
import {ReactRouter6Adapter} from 'use-query-params/adapters/react-router-6';
import {QueryParamProvider} from 'use-query-params';
import SendCampaignComponent from './Components/SendCampaignComponent/SendCampaignComponent';
import CreateTeamComponent from './Components/CreateTeamComponent/CreateTeamComponent';
import UpdateTeamComponent from './Components/UpdateTeamComponent/UpdateTeamComponent';
import TeamsComponent from './Components/TeamsComponent/TeamsComponent';
import APIDocsComponent from './Components/APIDocsPage/APIDocsComponent';
import TypographyComponent from './Components/TypographyComponent/TypographyComponent';
import HomePageComponent from './Components/HomePageComponent/HomePageComponent';
import ContentGuidanceEmailComponent from './Components/ContentGuidanceEmailPage/ContentGuidanceEmailComponent';
import ContentGuidanceComponent from './Components/ContentGuidancePage/ContentGuidanceComponent';
import ContentGuidancePushComponent from './Components/ContentGuidancePushPage/ContentGuidancePushComponent';

var oktaAuth = new OktaAuth({
    issuer: `${process.env.REACT_APP_OAUTH2_ISSUER_URL}`,
    clientId: `${process.env.REACT_APP_OAUTH2_CLIENT_ID}`,
    redirectUri: window.location.origin + '/login/callback'
});
var restoreOriginalUri = async (_oktaAuth, originalUri) => {
    window.location.replace(toRelativeUrl(originalUri || '/', window.location.origin));
};
ReactDOM.render(
    <GlobalState>
        <Security oktaAuth={ oktaAuth } restoreOriginalUri={ restoreOriginalUri }>
            <Router>
                <ThemeProvider theme={ theme }>
                    <GlobalStyle/>
                    <Routes>
                        {/* This login page is replaced by Home page
                        <Route exact path='/login' element={
                            // Login rout is unauthenticated and hence we do not wrap it with ProtectedComponent
                            <>
                                <GlobalNavWrapper component={ LoginComponent }/>
                            </>
                        }/>
                        */}
                        <Route exact path='/' element={
                            // home page route is unauthenticated and hence we do not wrap it with ProtectedComponent
                            <HomePageComponent/>
                        }/>
                        <Route path='/login/callback' element={ <LoginCallback errorComponent={ LoginComponent }/> }/>
                        <Route exact path='/dashboard' element={
                            <ProtectedComponent>
                                <GlobalNavWrapper component={ DashboardComponent }/>
                            </ProtectedComponent>
                        }/>
                        <Route exact path='/apidocs' element={
                            <ProtectedComponent>
                                <GlobalNavWrapper component={ APIDocsComponent }/>
                            </ProtectedComponent>
                        }/>
                        <Route exact path='/content-guidance' element={
                            <ProtectedComponent>
                                <GlobalNavWrapper component={ ContentGuidanceComponent }/>
                            </ProtectedComponent>
                        }/>
                        <Route exact path='/content-guidance/push' element={
                            <ProtectedComponent>
                                <GlobalNavWrapper component={ ContentGuidancePushComponent }/>
                            </ProtectedComponent>
                        }/>
                        <Route exact path='/content-guidance/email' element={
                            <ProtectedComponent>
                                <GlobalNavWrapper component={ ContentGuidanceEmailComponent }/>
                            </ProtectedComponent>
                        }/>
                        <Route exact path='/AccessRequest' element={
                            <ProtectedComponent>
                                <GlobalNavWrapper component={ AccessRequestComponent }/>
                            </ProtectedComponent>
                        }/>
                        <Route exact path='/maintenance' element={
                            <ProtectedComponent>
                                <GlobalNavWrapper component={ OutageBannerComponent }/>
                            </ProtectedComponent>
                        }/>
                        <Route exact path='/agencies' element={
                            <ProtectedComponent>
                                <GlobalNavWrapper component={ AgencyListComponent } restricted={ true }/>
                            </ProtectedComponent>
                        }/>
                        <Route exact path='/send-notifications' element={
                            <ProtectedComponent>
                                <GlobalNavWrapper component={ SendNotificationComponent } restricted={ true }/>
                            </ProtectedComponent>
                        }/>
                        <Route exact path='/preference-tiles' element={
                            <ProtectedComponent>
                                <GlobalNavWrapper component={ PreferenceTileListComponent } restricted={ true }/>
                            </ProtectedComponent>
                        }/>
                        <Route exact path='/preference-tiles/add-service-tile' element={
                            <ProtectedComponent>
                                <GlobalNavWrapper component={ CreatePreferenceTileComponent } restricted={ true }/>
                            </ProtectedComponent>
                        }/>
                        <Route exact path='/preference-tiles/:serviceCode' element={
                            <ProtectedComponent>
                                <GlobalNavWrapper component={ UpdatePreferenceTileComponent } restricted={ true }/>
                            </ProtectedComponent>
                        }/>
                        <Route exact path='/agencies/add-agency' element={
                            <ProtectedComponent>
                                <GlobalNavWrapper component={ CreateAgencyComponent } restricted={ true }/>
                            </ProtectedComponent>
                        }/>
                        <Route exact path='/agencies/update-agency/:agencyCode' element={
                            <ProtectedComponent>
                                <GlobalNavWrapper component={ UpdateAgencyComponent } restricted={ true }/>
                            </ProtectedComponent>
                        }/>
                        <Route exact path='/add-service' element={
                            <ProtectedComponent>
                                <GlobalNavWrapper component={ CreateServiceComponent } restricted={ true }/>
                            </ProtectedComponent>
                        }/>
                        <Route exact path='/update-service/:serviceCode' element={
                            <ProtectedComponent>
                                <GlobalNavWrapper component={ UpdateServiceComponent } restricted={ true }/>
                            </ProtectedComponent>
                        }/>
                        <Route exact path='/services/:agencyCode' element={
                            <ProtectedComponent>
                                <GlobalNavWrapper component={ ServiceListComponent } restricted={ true }/>
                            </ProtectedComponent>
                        }/>
                        <Route exact path='/add-notification/service/:serviceCode' element={
                            <ProtectedComponent>
                                <QueryParamProvider
                                    adapter={ ReactRouter6Adapter }>
                                    <GlobalNavWrapper component={ CreateNotificationComponent }/>
                                </QueryParamProvider>
                            </ProtectedComponent>
                        }/>
                        <Route exact path='/update-notification/:notificationCode' element={
                            <ProtectedComponent>
                                <QueryParamProvider
                                    adapter={ ReactRouter6Adapter }>
                                    <GlobalNavWrapper component={ UpdateNotificationComponent }/>
                                </QueryParamProvider>
                            </ProtectedComponent>
                        }/>
                        <Route exact path='/users/update-user/:userId' element={
                            <ProtectedComponent>
                                <GlobalNavWrapper component={ UpdateUserComponent } restricted={ true }/>
                            </ProtectedComponent>
                        }/>
                        <Route exact path='/users/add-user' element={
                            <ProtectedComponent>
                                <GlobalNavWrapper component={ CreateUserComponent } restricted={ true }/>
                            </ProtectedComponent>
                        }/>
                        <Route exact path='/users' element={
                            <ProtectedComponent>
                                <GlobalNavWrapper component={ UserManagementComponent } restricted={ true }/>
                            </ProtectedComponent>
                        }/>
                        <Route exact path='/teams/add' element={
                            <ProtectedComponent>
                                <GlobalNavWrapper component={ CreateTeamComponent } restricted={ true }/>
                            </ProtectedComponent>
                        }/>
                        <Route exact path='/teams/:teamId/update' element={
                            <ProtectedComponent>
                                <GlobalNavWrapper component={ UpdateTeamComponent } restricted={ true }/>
                            </ProtectedComponent>
                        }/>
                        <Route exact path='/send-campaign' element={
                            <ProtectedComponent>
                                <GlobalNavWrapper component={ SendCampaignComponent } restricted={ true }/>
                            </ProtectedComponent>
                        }/>
                        <Route exact path='/admin' element={
                            <ProtectedComponent>
                                <GlobalNavWrapper component={ AdminComponent } restricted={ true }/>
                            </ProtectedComponent>
                        }/>
                        <Route exact path='/teams' element={
                            <ProtectedComponent>
                                <GlobalNavWrapper component={ TeamsComponent } restricted={ true }/>
                            </ProtectedComponent>
                        }/>
                        <Route exact path='/typography' element={
                            <ProtectedComponent>
                                <GlobalNavWrapper component={ TypographyComponent } restricted={ true }/>
                            </ProtectedComponent>
                        }/>
                        <Route exact path='/dashboard' element={
                            <ProtectedComponent>
                                <GlobalNavWrapper component={ DashboardComponent } restricted={ false }/>
                            </ProtectedComponent>
                        }/>
                    </Routes>
                </ThemeProvider>
            </Router>
        </Security>
    </GlobalState>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

import React, {useEffect} from 'react';
import {
    CustomHeaderContainer,
    CustomHeaderContainerTitle,
    CustomHeaderMain,
    CustomHeaderMainContainer,
    CustomHeaderMainContainerButtonWrapper,
    CustomHeaderTop
} from './CustomHeader.styled';
import CustomHeaderLogo from './CustomHeaderLogo';
import Button from '@snsw/react-component-library/build/Components/Button/Button';
import {toRelativeUrl} from '@okta/okta-auth-js';
import {useOktaAuth} from '@okta/okta-react';
import apiServices from '../../../services/api-services';

const CustomHeader = ({ setAlert }) => {

    const { oktaAuth, authState } = useOktaAuth();

    const doLogin = async () => {
        const originalUri = toRelativeUrl('/dashboard', window.location.origin);
        oktaAuth.setOriginalUri(originalUri);
        oktaAuth.signInWithRedirect().then(() => {
            console.log('Successfully redirected to OKTA');
        })
            .catch(() => {
                console.log('Error for OKTA login');
                setAlert(prevState => ({
                    ...prevState,
                    variant: 'error',
                    title: 'Login failed',
                    description: 'There has been an error logging in. Please recheck ' +
                            'the URL and try again. Alternatively, contact us for support.',
                    visible: true
                }));

            });
    };

    useEffect(() => {
        if (!authState) {
            return;
        }

        if (authState?.isAuthenticated) {
            //calling login info controller in bff to log login event for audit trail
            apiServices.getLoginInfo().then(data => console.log(`logged in user email is ${data}`))
                .catch(error => { console.log(error); });
        }
    }, [oktaAuth, authState]);

    return (
        <div role='navigation'>
            <CustomHeaderTop>
                <CustomHeaderContainer>
                    <CustomHeaderContainerTitle>Digital Notifications</CustomHeaderContainerTitle>
                </CustomHeaderContainer>
            </CustomHeaderTop>
            <CustomHeaderMain>
                <CustomHeaderMainContainer>
                    <CustomHeaderLogo/>
                    <CustomHeaderMainContainerButtonWrapper>
                        <Button
                            onClick={ doLogin }
                            variant='primary'
                            id={ authState?.isAuthenticated ? 'dashboardBtn' : 'loginBtn' }
                        >{authState?.isAuthenticated ? 'Dashboard' : 'Log in'}</Button>
                    </CustomHeaderMainContainerButtonWrapper>
                </CustomHeaderMainContainer>
            </CustomHeaderMain>
        </div>
    );
};
export default CustomHeader;

import React, {useEffect, useRef, useState} from 'react';
import LogoComponent from '../LogoComponent/LogoComponent';
import {Modal} from '@snsw/react-component-library';
import {useOktaAuth} from '@okta/okta-react';
import {useLocation, useNavigate} from 'react-router-dom';
import {useBoolean} from '@snsw/react-component-library/build/utils';
import {useGlobalState, useUserRoleState} from '../GlobalStateComponent/GlobalState';
import apiServices from '../../services/api-services';
import PropTypes from 'prop-types';
import SingleSelectComponent from '../CommonComponents/SelectComponent/SingleSelectComponent';
import SidebarItem from './SidebarItem';
import {
    DividerLine,
    ItemList,
    LogoAndTitleWrapper,
    SidebarChildren,
    SidebarContainer,
    SidebarItemWrapper,
    SidebarTitle,
    UserItemContainer,
    UserItemList, ListWrapper, ListItemWrapper
} from './SidebarMenu.styled';

const SidebarMenu = (props) => {
    const navigate = useNavigate();
    const {oktaAuth, authState} = useOktaAuth();
    const [user, setUser] = useState(null);
    const [showModal, open, close] = useBoolean(false);
    const [inProd, setInProd] = useGlobalState();
    const {userRole, setUserRole} = useUserRoleState();
    const environmentOptions = [
        {value: false, label: 'Non-Prod'},
        {value: true, label: 'Prod'}
    ];
    const [expandedItems, setExpandedItems] = useState([]);
    const [selectedItem, setSelectedItem] = useState(''); // State to track selected item
    const isInitialMount = useRef(true);
    const [isDividerLineVisible, setIsDividerLineVisible] = useState(false);
    const sibebarItemsDiv = useRef(null);
    const ignoreRef = useRef(null);
    const location = useLocation();

    const handleItemClick = (item) => {
        // If clicked item takes to an external source, then that item should not look selected after being clicked
        // Also if it has children and is clicked (expanded), it should not have the selection styling either
        if (!item.isExternalSource && !(item.children && item.children.length > 0)) {
            // localStorage.setItem('selectedItem', item.label); // Store the selected item's label in local storage
            setSelectedItem(item.label);
        }
        if (item.children && item.children.length > 0) {
            setExpandedItems((prevState) =>
                prevState.includes(item.label) ?
                    prevState.filter((label) => label !== item.label)
                    : [...prevState, item.label]
            );
        }

        if (item.onClick && typeof item.onClick === 'function') {
            item.onClick(); // Call the provided onClick function
            return;
        }
        if (item.isExternalSource) {
            // Navigate to the external URL
            window.open(
                item.target,
                '_blank'
            );
            return;
        }

        if (item.link) {
            navigate(item.link);
        }
    };

    const isUserAdmin = () => {
        return (authState?.isAuthenticated && userRole && userRole?.userRoles[0]?.name === 'ADMIN');
    };
    const isUserLoggedIn = () => {
        return authState?.isAuthenticated;
    };

    const sidebarItems = [
        {
            label: 'Dashboard',
            link: '/dashboard',
            visible: isUserLoggedIn(),
            icon: '../../assets/icons/home.svg', iconBlue: '../../assets/icons/home_blue.svg',
            iconBlueBold: '../../assets/icons/home_blue_bold.svg'
        },
        {
            label: 'Content guidance',
            link: '/content-guidance',
            children: [
                {label: 'Push notifications', link: '/content-guidance/push', visible: true},
                {label: 'Emails', link: '/content-guidance/email', visible: true}
            ],
            visible: true,
            icon: '../../assets/icons/content.svg', iconBlue: '../../assets/icons/content_blue.svg',
            iconBlueBold: '../../assets/icons/content_blue_bold.svg'
        },
        {
            label: 'API Docs', link: '/apidocs', visible: true,
            icon: '../../assets/icons/code.svg', iconBlue: '../../assets/icons/code_blue.svg',
            iconBlueBold: '../../assets/icons/code_blue_bold.svg'
        },
        {
            label: 'Reporting',
            target: 'https://reporting.service.nsw.gov.au/#/signin?isDefaultIdentityPoolLogin=true&redirect=%2Fviews%2FDigitalNotifications_15939963298910%2FDigitalNotifications%3F%253Aiid%3D1',
            visible: true, icon: '../../assets/icons/reporting.svg', iconBlue: '../../assets/icons/reporting_blue.svg',
            iconBlueBold: '../../assets/icons/reporting_blue_bold.svg', isExternalSource: true
        },
        {
            label: 'Contact us',
            target: 'mailto:notifications@service.nsw.gov.au',
            visible: true,
            icon: '../../assets/icons/phone.svg', iconBlue: '../../assets/icons/phone_blue.svg',
            iconBlueBold: '../../assets/icons/phone_blue_bold.svg', isExternalSource: true
        },
        {
            label: 'Admin',
            children: [
                {label: 'Users', link: '/admin', visible: isUserAdmin()},
                {label: 'Teams', link: '/teams', visible: isUserAdmin()},
                {label: 'Agencies', link: '/agencies', visible: isUserAdmin()},
                {label: 'Preferences page', link: '/preference-tiles', visible: isUserAdmin()},
                {label: 'Test campaign', link: '/send-notifications', visible: isUserAdmin()},
                {label: 'Send campaign', link: '/send-campaign', visible: isUserAdmin()}
            ], visible: isUserAdmin(), icon: '../../assets/icons/cog.svg', iconBlue: '../../assets/icons/cog_blue.svg',
            iconBlueBold: '../../assets/icons/cog_blue_bold.svg', isNotLink: true
        }];

    // If username is longer than user div, it is truncated with '...' on the end
    const getUserName = () => {
        if (!user) {
            return '';
        }
        return user.name.length > 19 ? `${user.name.substring(0, 19) + '...'}` : user.name;
    };
    const userItem =
        {
            isUserItem: true,
            label: getUserName(),
            children: [
                {
                    label: 'Log out',
                    onClick: open,
                    icon: '../../assets/icons/sign_out.svg',
                    visible: isUserLoggedIn(),
                    isNotLink: false
                }
            ], visible: isUserLoggedIn(), icon: '../../assets/icons/profile_icon.svg', isNotLink: true
        };

    useEffect(() => {
        if (!authState) {
            return;
        }

        if (authState?.isAuthenticated) {
            //calling login info controller in bff to log login event for audit trail
            apiServices.getLoginInfo().then(data => console.log(`logged in user email is ${data}`))
                .catch(error => {
                    console.log(error);
                });
            oktaAuth.getUser().then((user) => {
                setUser(user);
            });
        }
    }, [oktaAuth, authState]);
    // Finds the item of the current path on screen by comparing that item's link to the location.pathname in the
    // useLocation hook used in the below useEffect
    const findCurrentItemsLink = (items, pathname) => {
        for (const item of items) {
            // If the item has children, recurse into them
            if (item.children && item.children.length > 0) {
                const child = findCurrentItemsLink(item.children, pathname);
                if (child) {
                    return child; // Return the child if match is found
                }
            }
            // Check the current item's path
            if (item.link === pathname) {
                return item; // Return the item if its link matches the pathname
            }
        }
        return null; // Return null if no matching path is found
    };
    useEffect(() => {
        if (isInitialMount.current) {
            // const storedExpandedItems = localStorage.getItem('expandedItems');
            // const storedSelectedItem = localStorage.getItem('selectedItem');
            // if (storedExpandedItems) {
            //     setExpandedItems(JSON.parse(storedExpandedItems));
            // }
            // if (storedSelectedItem !== 'null') {
            //     // setSelectedItem(JSON.parse(storedSelectedItem));
            // }

            // If user enters Portal from a resource other than the root, this will set the nav to highlight that route
            const currentItem = findCurrentItemsLink(sidebarItems, location.pathname);
            if (currentItem) {
                // localStorage.setItem('selectedItem', currentItem.label); // Store the selected item's label in local storage
                setSelectedItem(currentItem.label); //Sets the item on the sidebar
                // If it cannot find that route in location, it is set to dashboard
            } else {
                // Default is Dashboard selected and all expandables collapsed
                setSelectedItem('Dashboard');
            }
            setExpandedItems([]);
            isInitialMount.current = false;
            // if not initial mount, findCurrentItemsLink() is run when ever the location hook changes
        } else {
            const currentItem = findCurrentItemsLink(sidebarItems, location.pathname);
            if (currentItem) {
                setSelectedItem(currentItem.label); //Sets the item on the sidebar
                // localStorage.setItem('expandedItems', JSON.stringify(expandedItems));
                // localStorage.setItem('selectedItem', JSON.stringify(selectedItem));
            }
        } // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location]);

    // Function that checks if sidebar is scrollable
    const checkIfSibebarItemsDivScrollable = () => {
        const div = sibebarItemsDiv.current;
        if (div) {
            setIsDividerLineVisible(div.scrollHeight > div.clientHeight);
        }
    };
    // Anytime the window is resized, this useEffect will check if the sidebar is scrollable and conditionally hide or
    // show the divider line
    useEffect(() => {
        window.addEventListener('resize', checkIfSibebarItemsDivScrollable);
        //cleans up event listener
        return () => window.removeEventListener('resize', checkIfSibebarItemsDivScrollable);
    }, []);
    // Anytime expandedItems changes, this useEffect will check if the sidebar is scrollable and conditionally hide or
    // show the divider line
    useEffect(() => {
        checkIfSibebarItemsDivScrollable();
    }, [expandedItems]);

    useEffect(() => {
        // This useEffect checks if the user item is expanded and if the user has clicked anywhere but on the user item
        // if so, the user item is collapsed
        const ifUserExpandedHandleClick = (e) => {
            if (expandedItems.includes(userItem.label) && ignoreRef.current && !ignoreRef.current.contains(e.target)) {
                setExpandedItems((prevState) => prevState.filter((label) => label !== userItem.label)
                );
            }
        };
        // Add the event listener when the component mounts
        document.addEventListener('click', ifUserExpandedHandleClick);
        // Return a cleanup function that removes the event listener when the component unmounts
        return () => {
            document.removeEventListener('click', ifUserExpandedHandleClick);
        };
    }, [userItem.label, expandedItems]);
    const logOut = () => {
        //calling login info controller in bff to log logout event for audit trail
        apiServices.getLogoutInfo().then(data => console.log(`logged out user email is :${data}`));
        // authenticateServices.logUserOut();
        oktaAuth.signOut({
            postLogoutRedirectUri: window.location.origin + '/',
            revokeAccessToken: false,
            revokeRefreshToken: false,
            clearTokensBeforeRedirect: true
        }).then(() => {
            setInProd(false);
            setUser(null);
            setUserRole(null);
            close();
            navigate('/');
        });
    };
    return (
        <SidebarContainer>
            <LogoAndTitleWrapper>
                <div style={ {padding: '20px 20px 15px'} }><LogoComponent/></div>
                <SidebarTitle>
                    {props.title}
                </SidebarTitle>
                {authState?.isAuthenticated ?
                    <>
                        {/*prod/non-prod dropdown is only shown to the user who has PROMOTE permission on notification*/}
                        {props.showInProdSelector && userRole && userRole?.permissions?.NOTIFICATION?.includes('PROMOTE') &&
                            <div style={ {
                                width: '170px',
                                marginLeft: '30px',
                                marginTop: '16px',
                                marginBottom: '8px'
                            } }>
                                <SingleSelectComponent
                                    label={ 'Select environment' }
                                    name={ 'environment' }
                                    options={ environmentOptions }
                                    autoFocus={ true }
                                    onChange={ (e) => setInProd(e.value) }
                                    placeholder={ '' }
                                    hideLabel
                                    defaultValue={ inProd ? environmentOptions[1] : environmentOptions[0] }
                                    isSearchable={ false }
                                />
                            </div>
                        }
                    </> : <></>
                }
                <DividerLine src={ '../../assets/icons/sidebar_line.svg' } alt={ 'Divider line' }
                    role={ 'img' } aria-hidden={ true } />
            </LogoAndTitleWrapper>
            <SidebarItemWrapper ref={ sibebarItemsDiv } prodSelector={
                // If user is authenticated and the prod/non prod selector is present, the sidebar size changes.
                authState?.isAuthenticated && props.showInProdSelector
                && userRole && userRole?.permissions?.NOTIFICATION?.includes('PROMOTE') }>
                <ItemList>
                    {sidebarItems.map((item, index) => (
                        <>
                            <SidebarItem
                                item={ item }
                                handleClick={ handleItemClick }
                                isExpanded={ expandedItems.includes(item.label) }
                                isSelected={ selectedItem === item.label } // Pass the selected state
                            />
                            {item.children && expandedItems.includes(item.label) && (
                                <ListItemWrapper>
                                    <SidebarChildren>
                                        {item.children.map((childItem, childIndex) => (
                                            <SidebarItem
                                                key={ childIndex }
                                                item={ childItem }
                                                handleClick={ handleItemClick }
                                                isSelected={ selectedItem === childItem.label } // Pass the selected state
                                            />
                                        ))}
                                    </SidebarChildren>
                                </ListItemWrapper>
                            )
                            }
                        </>
                    ))}
                </ItemList>
            </SidebarItemWrapper>
            {/*user side menu item*/}
            <UserItemContainer ref={ ignoreRef }>
                <DividerLine src={ '../../assets/icons/sidebar_line.svg' } alt={ 'Divider line' }
                    hidden={ !isDividerLineVisible }/>
                <ListWrapper>
                    <SidebarItem
                        item={ userItem }
                        handleClick={ handleItemClick }
                        isExpanded={ expandedItems.includes(userItem.label) }
                        isSelected={ selectedItem === userItem.label } // Pass the selected state
                    />
                </ListWrapper>
                {userItem.children && expandedItems.includes(userItem.label) && (
                    <UserItemList>
                        {userItem.children.map((childItem, childIndex) => (
                            <SidebarItem
                                item={ childItem }
                                handleClick={ handleItemClick }
                                isSelected={ selectedItem === childItem.label } // Pass the selected state
                            />

                        ))}
                    </UserItemList>
                )
                }
            </UserItemContainer>

            {showModal && (
                <Modal
                    title='Are you sure you want to logout?'
                    buttons={ [
                        {text: 'Log out', onClick: logOut},
                        {
                            text: 'Cancel', onClick: () => {
                                setSelectedItem('');
                                close();
                            }
                        }
                    ] }
                />
            )}
        </SidebarContainer>
    );
};
SidebarMenu.propTypes = {
    title: PropTypes.string,
    showInProdSelector: PropTypes.bool
};
export default SidebarMenu;

import AddSegmentComponent from './AddSegmentComponent';
import React, {useEffect, useRef, useState} from 'react';
import {imagePath} from '../GlobalStateComponent/GlobalState';
import {AddSegmentButton} from '../SendCampaignComponent/SendCampaignComponent.styled';
import EstimateCustomersComponent from './EstimateCustomersComponent';

const SegmentedCampaignComponent = ({
    refContainerWidth,
    setCampaignNotificationRequest,
    showAddSegment,
    selectedConditions
}) => {
    const counter = useRef(0);
    const [segments, setSegments] = useState({});

    const iconAdd = <img
        src={ `${imagePath}plus_round_circle.svg` }
        alt={ 'add-segment-condition' }
        style={ {marginRight: '6px'} }/>;

    const handleAddSegmentButtonClick = () => {
        setSegments(prevState => {
            const segments = {
                ...prevState,
                [counter.current]: {}
            };
            //Counter used as key for each AddSegment component, needed in component logic
            counter.current++;
            return segments;
        });
    };

    // !This should only be done when you click send, not on every state change! EDIT LATER
    useEffect(() => {
        //Amalgamates all segment conditions into one object ->
        const conditions = {
            suburb: {
                contain: new Set(),
                notContain: new Set()
            },
            lga: {
                contain: new Set(),
                notContain: new Set()
            }
        };

        Object.values(segments).forEach(segment => {
            ['suburb', 'lga'].forEach(areaCondition => {
                if (segment[areaCondition]) {
                    ['contain', 'notContain'].forEach(containCondition => {
                        if (segment[areaCondition][containCondition]) {
                            segment[areaCondition][containCondition].forEach(
                                area => conditions[areaCondition][containCondition].add(area));
                        }
                    });
                }
            });
        });

        conditions.suburb.contain = Array.from(conditions.suburb.contain);
        conditions.suburb.notContain = Array.from(conditions.suburb.notContain);
        conditions.lga.contain = Array.from(conditions.lga.contain);
        conditions.lga.notContain = Array.from(conditions.lga.notContain);

        setCampaignNotificationRequest(prevState => {
            return {
                ...prevState,
                segmentConditions: conditions
            };
        });

    }, [segments, setCampaignNotificationRequest]);

    const addSegmentButton = <AddSegmentButton variant='tertiary' data-testid='add-segment-btn'
        onClick={ handleAddSegmentButtonClick }
        iconStart={ iconAdd }>Add segment condition
    </AddSegmentButton>;

    return (
        <div style={ {display: showAddSegment ? 'block' : 'none'} }>
            <>
                {Object.keys(segments).map((segmentKey) => {
                    return (
                        <AddSegmentComponent
                            key={ segmentKey }
                            segmentKey={ segmentKey }
                            refContainerWidth={ refContainerWidth }
                            setSegments={ setSegments }
                            setCampaignNotificationRequest={ setCampaignNotificationRequest }
                            isFirstCondition={ Object.keys(segments)[0] === segmentKey }
                        ></AddSegmentComponent>
                    );
                })}
                {addSegmentButton}
                <EstimateCustomersComponent selectedConditions={ selectedConditions }/>
            </>
        </div>

    );
};

export default SegmentedCampaignComponent;
import React from 'react';
import AnchorMenuLayoutComponent from '../CommonComponents/AnchorMenuLayoutComponent/AnchorMenuLayoutComponent';
import ContentGuidanceContentComponent from './ContentGuidanceContentComponent';

const ContentGuidanceComponent = () => {

    return (
        <AnchorMenuLayoutComponent
            title='Content guidance'
            contentComponent={ ContentGuidanceContentComponent }
            anchorMenuComponent={ null }
            anchorMenuMetaData={ null }
        />
    );
};

export default ContentGuidanceComponent;

import React, {useEffect, useState} from 'react';
import {
    ComponentWrapper,
    ContentWrapper,
    ImgWrapper,
    Img,
    Heading,
    Body,
    InquireFormInput, InquireFieldset, InquireButton, Alert, P
} from './InquireComponent.styled';
import {Form, IconNotificationSuccess, Textarea} from '@snsw/react-component-library';
import {useBoolean} from '@snsw/react-component-library/build/utils';
import apiServices from '../../../services/api-services';

const InquireComponent = () => {
    const [submitted, setSubmitted] = useBoolean(false);
    const [alert, setAlert] = useState( {
        variant: '',
        title: '',
        description: '',
        visible: false
    });
    const [inputErrors, setInputErrors] = useState({
        name: {hasError: false, errorMessage: ''},
        email: {hasError: false, errorMessage: ''},
        agency: {hasError: false, errorMessage: ''},
    });
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        agency: '',
        projectDescription: ''
    });
    const validateTextField = (input, inputField) => {
        let valid = true;
        if (input.length === 0) {
            setInputErrors(prevState => ({
                ...prevState,
                [inputField]: {hasError: true, errorMessage: 'Field required'}
            }));
            valid = false;
        }
        if (inputField === 'email' && input.length !== 0) {
            const pattern = /^\w+(?:\.\w+)*@\w+(?:\.\w+)+$/;
            valid = pattern.test(input.toString()) && input.toString().endsWith('.nsw.gov.au');
            if(!valid) {
                setInputErrors(prevState => ({
                    ...prevState,
                    [inputField]: {hasError: true, errorMessage: 'A government email is required'}
                }));
            }
        }
        return valid;
    };
    const validateContactForm = async () => {
        const nameValidation = validateTextField(formData.name, 'name');
        const emailValidation = validateTextField(formData.email, 'email');
        const agencyValidation = validateTextField(formData.agency, 'agency');
        return nameValidation && emailValidation && agencyValidation;
    };
    const handleChange = event => {
        const {name, value} = event.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
        setInputErrors(prevState => ({
            ...prevState,
            [name]: {hasError: false, errorMessage: ''}
        }));
    };
    const submitForm = async (e) => {
        validateContactForm().then((valid) => {
            let recaptchaToken;
            if (valid) {
                e.preventDefault();
                window.grecaptcha.enterprise.ready(async () => {
                    recaptchaToken = await window.grecaptcha.enterprise
                        .execute(`${process.env.REACT_APP_RECAPTCHA_KEY}`, {action: 'submitContactForm'});
                    apiServices.submitContactForm(formData, 'DST', recaptchaToken)
                        .then((response) => {
                            window.dataLayer = window.dataLayer || [];
                            window.dataLayer.push({
                                event: 'portal_inquire'
                            });
                            setSubmitted(true);
                        })
                        .catch(e => {
                            setAlert(prevState => ({
                                ...prevState,
                                variant: 'error',
                                title: 'Submission failed',
                                description: 'There has been an error on submitting the form. Please recheck and try again.',
                                visible: true
                            }));
                        });
                });
            } else {
                const errorElement = document.querySelectorAll('span[class^=\'FormError\']')[0]?.previousSibling;
                if (errorElement !== undefined) {
                    errorElement.scrollIntoView({
                        alignToTop: true,
                        behavior: 'smooth',
                        block: 'center'
                    });
                }
            }
        });
    };
    useEffect(() => {
        if (alert.visible) {
            const timer = setTimeout(() => {
                setAlert(prevState => ({
                    ...prevState,
                    visible: false
                }));
            }, 60000);
            return () => {
                clearTimeout(timer);
            };
        }
    }, [alert]);
    return (
        <ComponentWrapper id='formSection'>
            <ContentWrapper>
                <div><Heading>Want to work with us?</Heading></div>
                {!submitted ?
                    <Body>
                        {/* eslint-disable-next-line max-len */}
                        <p>Head over to Digital Marketplace, and we’ll respond within 5 business days. We’ll only accept inquiries from NSW Government accounts.</p>
                    </Body> :

                    <Body ><IconNotificationSuccess data-testid={ 'success-icon' }
                        style={ {display:'inline-block', marginRight:'10px',position: 'relative',
                            verticalAlign: 'middle'} }/>
                    <P data-testid='successInfo' style={ {display:'inline-block'} }>
                            Application submitted. We will be in touch with you soon.</P></Body>
                }

                {(alert.visible) &&<Alert id='login-status' variant={ alert.variant } title={ alert.title }>
                    <p id='loginStatusDesc'>{alert.description}</p>
                </Alert>}

                {!submitted && <Form data-test='contact-form'>
                    <InquireFormInput
                        label='Your name'
                        name='name'
                        onChange={ handleChange }
                        errorMessage={ inputErrors.name.errorMessage }
                        hasError={ inputErrors.name.hasError }
                    />
                    <InquireFormInput
                        label='Email'
                        name='email'
                        onChange={ handleChange }
                        errorMessage={ inputErrors.email.errorMessage }
                        hasError={ inputErrors.email.hasError }
                    />
                    <InquireFormInput
                        label='Your government agency'
                        name='agency'
                        onChange={ handleChange }
                        errorMessage={ inputErrors.agency.errorMessage }
                        hasError={ inputErrors.agency.hasError }
                    />
                    <InquireFieldset
                        legend= 'Project description'
                        smallLegend
                        helpMessage='Optional'
                    >
                        <Textarea
                            label='Project description'
                            name='projectDescription'
                            data-testid='descriptionField'
                            rows={ 'medium' }
                            onChange={ handleChange }
                        />
                    </InquireFieldset>
                    <InquireButton
                        onClick={ submitForm }
                        variant='primary'
                        id='inquireFormSubmitBtn'
                    >Inquire</InquireButton>

                </Form>}

            </ContentWrapper>
            <ImgWrapper>
                <Img src={ `${process.env.PUBLIC_URL}/assets/images/inquire.png` } alt='inquire'></Img>
            </ImgWrapper>
        </ComponentWrapper>
    );
};

export default InquireComponent;

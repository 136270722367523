import React, {Component} from 'react';
import {components, default as ReactSelect} from 'react-select';
import {CustomIconClose, SelectCountCircle, SelectLabel, SelectSpanError} from './SelectComponent.styled';
import {colors, fontSize, globalFont} from '../Typography/Typography.styled';
import {
    customTheme,
    getBaseControlStyles,
    getOptionStyles,
} from './SingleSelectComponent';
import {SmallButton} from '../CustomComponents/CustomComponents.styled';

const Option = (props) => {
    const inputId = `${props.selectProps.inputId}-checkbox-${props.value}`;
    return (
        <div>
            <components.Option { ...props }>
                <input
                    id={ inputId }
                    type='checkbox'
                    checked={ props.isSelected }
                    onChange={ () => null }
                />{' '}
                <label
                    htmlFor={ inputId }
                    style={ {fontSize: '14px', color: colors.brand.snswDarkGrey} }>{props.label}</label>
            </components.Option>
        </div>
    );
};

const MultiValueRemove = (props) => {
    return (
        <components.MultiValueRemove { ...props }>
            <CustomIconClose/>
        </components.MultiValueRemove>
    );
};

const ClearIndicator = (props) => {
    return (
        <components.ClearIndicator { ...props }>
            <SmallButton
                data-testid={ `clear-btn-for-segment-${ props?.selectProps?.segmentKey }` }
                style={ {fontSize: `${fontSize.body}` } }
            >
                Clear all
            </SmallButton>
        </components.ClearIndicator>
    );
};

const MultiValue = (props) => {
    const { hideTags } = props.selectProps;
    if (hideTags) {
        return null;
    }

    return <components.MultiValue { ...props } />;
};

const ValueContainer = ({ children, ...props }) => {
    const { getValue, selectProps } = props;
    const activeSelectedOptions = getValue();
    const count = activeSelectedOptions.length;
    const spanCount = `${selectProps.placeholder?.toLowerCase()}-count`;

    return (
        <components.ValueContainer { ...props }>
            {count > 0 && selectProps.hideTags ? (
                <>
                    <div style={ { display: 'flex', alignItems: 'center' } }>
                        {selectProps.placeholder}
                        <SelectCountCircle data-testid={ spanCount }>{count}</SelectCountCircle>
                    </div>
                    {children[1]}
                </>
            ) : (
                children
            )}
        </components.ValueContainer>
    );
};

const Menu = (props) => (
    <components.Menu { ...props } role='listbox'>
        {props.children}
    </components.Menu>
);

export default class MultiSelectComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            optionSelected: this.props.initialValue
        };
    }

    componentDidUpdate(prevProps) {
        if (this.props.initialValue !== prevProps.initialValue) {
            this.setState({
                optionSelected: this.props.initialValue
            });
        }
    }

    handleChange = (selected) => {
        //if ALL option is selected, then we need to unselect other options.
        //while ALL is selected, user shouldn't select another option
        const allOption = selected.find(option => option.label === 'ALL');
        if (allOption) {
            selected = [allOption];
        }
        this.setState({
            optionSelected: selected
        });
        // Check if the parent has provided an `onOptionChange` prop as a function
        if (this.props.onOptionChange) {
            this.props.onOptionChange(selected); // Pass the selected value to the parent
        }
    };

    render() {
        const selectInputWidth = this.props.width || '528px';

        // Define styles based on the condition
        const customStyles = {
            control: (provided, state) => ({
                ...provided,
                ...getBaseControlStyles(state),
                minHeight: '42px',
                width: selectInputWidth,
                borderColor: state.isFocused ? `${colors.grey.neutral200}` : `${colors.grey.geyser}`
            }),
            multiValue: (provided) => ({
                ...provided,
                background: colors.brand.snswInfoBlueLight,
                color: colors.brand.snswPrimaryBlue,
                fontFamily: globalFont,
                width: 'fit-content'
            }),
            multiValueLabel: (provided) => ({
                ...provided,
                color: colors.brand.snswPrimaryBlue,
                fontSize: `${fontSize.body}`
            }),
            option: (provided, state) => ({
                ...provided,
                ...getOptionStyles(state),
                minWidth: selectInputWidth
            }),
            menu: (provided) => ({
                ...provided,
                minWidth: 'fit-content',
                width: selectInputWidth,
                position: 'absolute',
                background: `${colors.white}`,
                zIndex: '2'
            }),
            valueContainer: (provided) => ({
                ...provided,
                marginRight: '-3rem'
            }),
            clearIndicator: (provided) => ({
                ...provided,
                marginRight: '-5rem'
            })
        };

        const customStylesError = {
            control: (provided, state) => ({
                ...provided,
                ...getBaseControlStyles(state),
                minHeight: '42px',
                width: selectInputWidth,
                maxWidth: selectInputWidth,
                border: `1.5px solid ${colors.status.errorRed.default}`
            })
        };
        const {options, hasError, errorMessage} = this.props;
        const selectStyles = hasError ? customStylesError : customStyles;

        return (
            <div
                className='d-inline-block'
                data-toggle='popover'
                data-trigger='focus'
                data-content='selecet ...'
            >
                <div className='Field-sc-owfyzl-0 kYjsvt field-item'
                    style={ {marginTop: this.props.hideLabel ? '1rem' : 'auto'} }
                >
                    <SelectLabel
                        style={ {display: this.props.hideLabel ? 'none' : 'block'} }
                        id={ `${this.props.name}-multi-select-label` }
                        data-testid={ `${this.props.name}-multi-select-label` }
                        htmlFor={ `multi-select-${this.props.name}` }
                    >{this.props.label}
                    </SelectLabel>
                </div>
                <ReactSelect
                    inputId={ `multi-select-${this.props.name}` }
                    placeholder={ this.props.placeholder }
                    aria-labelledby={ `${this.props.name}-multi-select-label` }
                    options={ options }
                    segmentKey={ this.props.segmentKey }
                    isMulti
                    closeMenuOnSelect={ false }
                    hideSelectedOptions={ false }
                    components={ {
                        Option,
                        ClearIndicator: this.props.isClearable ? ClearIndicator : null,
                        IndicatorSeparator: null,
                        MultiValueRemove,
                        MultiValue,
                        ValueContainer,
                        Menu
                    } }
                    onChange={ this.handleChange }
                    allowSelectAll={ true }
                    value={ this.state.optionSelected }
                    styles={ selectStyles }
                    style={ {width: selectInputWidth} }
                    theme={ customTheme }
                    isDisabled={ this.props.isDisabled || false }
                    hideTags={ this.props.hideTags || false }
                    isSearchable={ this.props.isSearchable || true }
                />
                {this.props.hasError && (
                    <SelectSpanError id='multi-select-error'>{errorMessage}</SelectSpanError>
                )}
            </div>
        );
    }
}

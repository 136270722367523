import React, {useEffect, useMemo, useState} from 'react';
import {columnWidths, FilterButton, TH, TR} from './DashboardComponent.styled';
import {IconAdd} from '@snsw/react-component-library/build/Icons/system';
import {Link, useLocation, useNavigate} from 'react-router-dom';
import {
    Button,
    Col,
    InPageAlert,
    Row,
    SkeletonHeading,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow
} from '@snsw/react-component-library';

import {
    imagePath,
    orderButtons,
    scrollToTop,
    useGlobalState,
    useUserRoleState
} from '../GlobalStateComponent/GlobalState';
import apiServices from '../../services/api-services';
import DashboardNotificationListItem from './DashboardNotificationListItem';
import {CustomTableContainer} from '../CommonComponents/CustomComponents/CustomComponents.styled';
import {alertTimeoutValues} from '../../constants/applicationConstants';
import SearchBar from '../CommonComponents/SearchBar/SearchBar';
import FilterPanelComponent from './FilterPanelComponent';
import {Heading1} from '../CommonComponents/Typography/Typography.styled';

export const extractNotificationChannels = (notificationConfig) => {
    const {showInInbox, sendSmsEnabled, sendEmailEnabled, sendPushEnabled} = notificationConfig;
    const channels = [];

    if (sendEmailEnabled) {
        channels.push('Email');
    }
    if (sendPushEnabled) {
        channels.push('Push');
    }
    if (showInInbox) {
        channels.push('Inbox');
    }
    if (sendSmsEnabled) {
        channels.push('SMS');
    }

    return channels.join(', ');
};
export const dateOptions = {
    year: 'numeric',
    month: 'short',
    day: 'numeric'
};

export const sort = (col, notifications, setNotifications, order, setOrder) => {
    // Define the comparison function based on the current order
    const comparisonFunction = (a, b, order) => {
        if (order === 'ASC') {
            return a < b ? 1 : -1;
        } else {
            return a > b ? 1 : -1;
        }
    };

    // Define the comparison function for dates
    const dateComparisonFunction = (a, b, order) => {
        const dateA = a ? new Date(a) : null;
        const dateB = b ? new Date(b) : null;
        return comparisonFunction(dateB, dateA, order);
    };

    // Define the sorting order
    const newOrder = order === 'ASC' ? 'DSC' : 'ASC';

    // Define the sorting function based on the column
    let sortingFunction;
    switch (col) {
    case 'Service':
        sortingFunction = (a, b) => comparisonFunction(
            a.service.description ? a.service.description.toLowerCase() : null,
            b.service.description ? b.service.description.toLowerCase() : null, newOrder);
        break;
    case 'Agency':
        sortingFunction = (a, b) => comparisonFunction(
            a.service.agency.description ? a.service.agency.description.toLowerCase() : null,
            b.service.agency.description ? b.service.agency.description.toLowerCase() : null, newOrder);
        break;
    case 'Channels':
        sortingFunction = (a, b) => comparisonFunction(extractNotificationChannels(a),
            extractNotificationChannels(b), newOrder);
        break;
    case 'Notification':
        sortingFunction = (a, b) => comparisonFunction(
            a.description ? a.description.toLowerCase() : null,
            b.description ? b.description.toLowerCase() : null, newOrder);
        break;
    case 'Code':
        sortingFunction = (a, b) => comparisonFunction(a.notificationCode,
            b.notificationCode, newOrder);
        break;
    case 'Updated':
        sortingFunction = (a, b) => dateComparisonFunction(a.updatedDate, b.updatedDate, newOrder);
        break;
    case 'Status':
        sortingFunction = (a, b) => comparisonFunction(a.status, b.status, newOrder);
        break;
    default:
        return;
    }

    // Sort notifications and update state
    setNotifications([...notifications.sort(sortingFunction)]);

    // Update the order state
    setOrder(newOrder);
};

const DashboardComponent = () => {
    const [inProd] = useGlobalState();
    const {userRole} = useUserRoleState();
    const navigate = useNavigate();
    const {state} = useLocation();
    const [notifications, setNotifications] = useState([]);
    const [displayedNotifications, setDisplayedNotifications] = useState([]);
    const [order, setOrder] = useState('ASC');
    const [showSyncAlerts, setShowSyncAlerts] = useState(false);
    const [loadingNotifications, setLoadingNotifications] = useState(false);
    const [alert, setAlert] = useState(state && state.alert ? {
        variant: state.alert.variant,
        title: state.alert.title,
        description: state.alert.description,
        visible: state.alert.visible
    } : {
        variant: '',
        title: '',
        description: '',
        visible: false
    });
    const [showFilters, setShowFilters] = useState(false);
    const [resultsFromSearch, setResultsFromSearch] = useState([]);
    const [resultsFromFilter, setResultsFromFilter] = useState([]);

    useEffect(() => {
        if (alert.visible) {
            const timer = setTimeout(() => {
                setAlert(prevState => ({
                    ...prevState,
                    visible: false
                }));
            }, 60000);
            return () => {
                clearTimeout(timer);
            };
        }
        if (state && state.syncToLowerStatus && state.syncToLowerStatus.visible) {
            //Set the showAlert to true so that it will be displayed
            setShowSyncAlerts(true);
            const timer = setTimeout(() => {
                setShowSyncAlerts(false);
                if (state && state.syncToLowerStatus) {
                    state.syncToLowerStatus.visible = false;
                }
            }, alertTimeoutValues['error']);
            return () => {
                clearTimeout(timer);
            };
        }
    }, [alert, state]);

    useEffect(() => {
        scrollToTop();
        if (userRole.userTeams && userRole.userTeams.length > 0) {
            setLoadingNotifications(true);
            apiServices.getAllNotificationsConfigs(inProd).then(notificationsData => {
                const notifications = notificationsData.map(notification => {
                    notification.serviceDescription = notification?.service?.description;
                    notification.agencyDescription = notification?.service?.agency?.description;
                    return notification;
                });
                sort('Updated', notifications, setNotifications, 'ASC', setOrder);
            })
                .catch(() => {
                    setAlert({
                        variant: 'error',
                        title: 'Error with notifications display',
                        visible: true,
                        description: 'Contact notifications support with error code 415'
                    });
                })
                .finally(() => setLoadingNotifications(false));
        }
    }, [inProd, userRole.userTeams]);

    useEffect(() => {

        if (resultsFromSearch.length === notifications.length && resultsFromFilter.length === notifications.length) {
            setDisplayedNotifications(notifications);
        } else {
            const resultsFromSearchSet = new Set(resultsFromSearch);
            const resultsFromFilterSet = new Set(resultsFromFilter);
            const intersection = [...resultsFromSearchSet].filter(item => resultsFromFilterSet.has(item));
            setDisplayedNotifications(Array.from(intersection));
        }
    }, [resultsFromSearch, resultsFromFilter, notifications, setDisplayedNotifications]);

    const handleOrderButtonClicked = (columnName) => {
        sort(columnName, displayedNotifications, setDisplayedNotifications, order, setOrder);
    };

    const searchKeys = useMemo(() => [
        'description', 'notificationCode', 'serviceDescription',
        'agencyDescription', 'channels', 'status'],
    []
    );
    const getEmptyTable = () => {
        const emptyTableRows = [];
        for (let i = 0; i < 3; i++) {
            const emptyTableCells = [];
            for (let j = 0; j < 8; j++) {
                emptyTableCells.push(
                    <TableCell key={ `${i}.${j}` } id={ `${i}.${j}` }>
                        <SkeletonHeading level={ 5 } noMargin/>
                    </TableCell>
                );
            }
            emptyTableRows.push(
                <TableRow id={ i } key={ i }>
                    {emptyTableCells}
                </TableRow>
            );
        }
        return emptyTableRows;
    };

    const navigateToCreate = () => {
        navigate('/add-notification/service/:serviceCode?from=dashboard');
    };

    return (
        <CustomTableContainer>
            <Heading1 id={ 'DashboardHeading' }>Dashboard</Heading1>
            <div style={ {display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: '1rem'} }>
                <div style={ {display: 'flex', gap: '10px'} }>
                    <SearchBar setResults={ setResultsFromSearch }
                        searchKeys={ searchKeys }
                        dataSource={ notifications }
                        dataSetName={ 'notification' }
                    />
                    <FilterButton id='filterButton' data-testid='filterButton' disableRipple={ true }
                        selected={ showFilters }
                        onChange={ () => {
                            setShowFilters(!showFilters);
                        } } value={ 'filtersButton' }>
                        <img alt={ 'filter-icon' } src={ `${imagePath}FilterIcon.svg` }/>
                        Filters
                        {showFilters ? <img src={ `${imagePath}ChevronUp.svg` } alt='IconChevronUp'/>
                            : <img src={ `${imagePath}ChevronDown.svg` } alt='IconChevronDown'/>}
                    </FilterButton>
                </div>
                {!inProd && userRole?.permissions?.NOTIFICATION?.includes('CREATE') &&
                    <Button
                        iconStart={ <IconAdd/> }
                        variant='primary'
                        as={ Link }
                        id={ 'createNotificationCTA' }
                        onClick={ navigateToCreate }
                        style={
                            {
                                width: 'Hug(231px)',
                                height: '42px',
                                padding: '16px 24px 16px 24px',
                                gap: '0px',
                                opacity: '0px',
                                textDecoration: 'auto',
                                fontFamily: 'Public Sans, serif'
                            }
                        }
                        disabled={ userRole.userTeams && userRole.userTeams.length === 0 }
                    >
                        Create notification
                    </Button>
                }
            </div>
            <FilterPanelComponent
                showFilter={ showFilters }
                dataSource={ notifications }
                setResults={ setResultsFromFilter }
            />
            {(alert.visible) &&
                <InPageAlert id='dashboard-status' variant={ alert.variant } title={ alert.title }>
                    <p id='notStatusDesc'>{alert.description}</p>
                </InPageAlert>
            }
            {
                (state && state.syncToLowerStatus && showSyncAlerts) &&
                state.syncToLowerStatus.alerts.map((row, index) => (
                    <CustomTableContainer>
                        <Row key={ index }>
                            <Col span={ 9 }>
                                <InPageAlert id={ `notification-status-${index}` } variant={ row.variant }
                                    title={ row.title }>
                                    <p data-test={ `notStatusDesc-${index}` }>{row.description}</p>
                                </InPageAlert>
                            </Col>
                        </Row>
                    </CustomTableContainer>
                ))
            }
            <div style={ {
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                marginTop: '7px',
                maxWidth: 'unset'
            } }>
                {
                    loadingNotifications ?
                        <Table id={ 'dashboard-empty-table' } style={ {background: '#FFFFFF', marginTop: '8px'} }>
                            <TableHead>
                                <TR key={ 'th-no-sort' } style={ {
                                    background: '#FFFFFF', borderBottom: '2px solid #DEE3E5',
                                    borderRadius: '0px 6px 0px 0px'
                                } }>
                                    <TH>Notification</TH>
                                    <TH>Code</TH>
                                    <TH>Service</TH>
                                    <TH>Agency</TH>
                                    <TH>Channels</TH>
                                    <TH>Updated</TH>
                                    <TH>Status</TH>
                                    <TH>
                                        <div/>
                                    </TH>
                                </TR>
                            </TableHead>
                            <TableBody key={ 'tbody-empty' }>
                                {getEmptyTable()}
                            </TableBody>
                        </Table>
                        :
                        // Notifications are returned from BFF, check for search query results -
                        displayedNotifications.length === 0 ?
                            <div>
                                <Table id={ 'dashboard-empty-table' } style={ {background: '#FFFFFF', marginTop: '8px'} }>
                                    <TableHead>
                                        <TR key={ 'th-no-sort' } style={ {
                                            background: '#FFFFFF', borderBottom: '2px solid #DEE3E5',
                                            borderRadius: '0px 6px 0px 0px'
                                        } }>
                                            <TH style={ {width: columnWidths.notification} }>Notification</TH>
                                            <TH style={ {width: columnWidths.code} }>Code</TH>
                                            <TH style={ {width: columnWidths.service} }>Service</TH>
                                            <TH style={ {width: columnWidths.agency} }>Agency</TH>
                                            <TH style={ {width: columnWidths.channels} }>Channels</TH>
                                            <TH style={ {width: columnWidths.updated} }>Updated</TH>
                                            <TH style={ {width: columnWidths.status} }>Status</TH>
                                            <TH style={ {width: columnWidths.actionButton} }>
                                                <div/>
                                            </TH>
                                        </TR>
                                    </TableHead>
                                    <TableBody/>
                                </Table>
                                {/* If search query yields no results, we display a different message
                                    than if the API retrieves no notifications*/}
                                <div style={ {
                                    width: 'auto',
                                    color: '#646974',
                                    font: 'Public Sans, Arial',
                                    paddingLeft: '16px',
                                    paddingTop: '16px'
                                } }>
                                    No notifications found. {notifications.length === 0 ?
                                        <>Create a new notification or check your access level with admin:
                                        notification@service.nsw.gov.au</>
                                        :
                                        <>Try again with a different search or filter.</>
                                    }
                                </div>
                            </div>
                            :
                            <Table id={ 'dashboard-table' } style={
                                {
                                    background: '#FFFFFF',
                                    marginTop: '8px',
                                    tableLayout: 'fixed'
                                }
                            }>
                                <TableHead>
                                    <TR key={ 'th-row' } style={ {
                                        background: '#FFFFFF', borderBottom: '2px solid #DEE3E5',
                                        borderRadius: '6px', overflow: 'hidden'
                                    } }>
                                        <TH key={ 'th-notifications' } style={ {width: columnWidths.notification} }>
                                            {orderButtons('Order notificationsinAscending/DescendingOrder',
                                                'Notification', handleOrderButtonClicked)}
                                        </TH>
                                        <TH key={ 'th-notificationCode' }
                                            style={ {width: columnWidths.code, padding: 'unset'} }>
                                            {orderButtons('Order notificationsinAscending/DescendingOrder',
                                                'Code', handleOrderButtonClicked)}
                                        </TH>
                                        <TH key={ 'th-service' } style={ {width: columnWidths.service} }>
                                            {orderButtons('Order notificationsinAscending/DescendingOrder',
                                                'Service', handleOrderButtonClicked)}
                                        </TH>
                                        <TH key={ 'th-agency' } style={ {width: columnWidths.agency} }>
                                            {orderButtons('Order notificationsinAscending/DescendingOrder',
                                                'Agency', handleOrderButtonClicked)}
                                        </TH>
                                        <TH key={ 'th-channels' } style={ {
                                            width: columnWidths.channels,
                                            minWidth: columnWidths.channels,
                                            maxWidth: columnWidths.channels,
                                            padding: 'unset',
                                            position: 'sticky',
                                            right: '226px',
                                            zIndex: '1',
                                            tableLayout: 'fixed',
                                            backgroundColor: 'white'
                                        } }>
                                            {orderButtons('Order notificationsinAscending/DescendingOrder',
                                                'Channels', handleOrderButtonClicked)}
                                        </TH>
                                        <TH key={ 'th-updatedDate' } style={ {
                                            width: columnWidths.updated,
                                            minWidth: columnWidths.updated,
                                            maxWidth: columnWidths.updated,
                                            padding: 'unset',
                                            position: 'sticky',
                                            right: '132px',
                                            zIndex: '1',
                                            tableLayout: 'fixed',
                                            backgroundColor: 'white'
                                        } }>
                                            {orderButtons('Order notificationsinAscending/DescendingOrder',
                                                'Updated', handleOrderButtonClicked)}
                                        </TH>
                                        <TH key={ 'th-status' } style={ {
                                            width: columnWidths.status,
                                            minWidth: columnWidths.status,
                                            maxWidth: columnWidths.status,
                                            padding: 'unset',
                                            position: 'sticky',
                                            right: '35px',
                                            zIndex: '1',
                                            tableLayout: 'fixed',
                                            backgroundColor: 'white'
                                        } }>
                                            {orderButtons('Order notificationsinAscending/DescendingOrder',
                                                'Status', handleOrderButtonClicked)}
                                        </TH>
                                        <TH key={ 'action-btn' }
                                            style={ {
                                                width: columnWidths.actionButton,
                                                minWidth: columnWidths.actionButton,
                                                maxWidth: columnWidths.actionButton,
                                                //padding: '0 16px',
                                                position: 'sticky',
                                                right: '0px',
                                                zIndex: '1',
                                                tableLayout: 'fixed',
                                                backgroundColor: 'white'
                                            } }>
                                        </TH>
                                    </TR>
                                </TableHead>
                                <TableBody role='list' key={ 'notifications-tbody' } style={ {background: '#FFFFFF'} }>
                                    {userRole.permissions.NOTIFICATION.includes('UPDATE') &&
                                        displayedNotifications.map((notification, index) => {
                                            notification.channels = extractNotificationChannels(notification);
                                            notification.updatedDate = notification.updatedDate ?
                                                (new Date(notification.updatedDate).toLocaleString('en-AU', dateOptions)) : '';
                                            return (
                                                <DashboardNotificationListItem
                                                    index={ index }
                                                    key={ `notification-${index}` }
                                                    notification={ notification }
                                                    setAgencyAlert={ setAlert }
                                                />
                                            );
                                        })}

                                </TableBody>
                            </Table>
                }
            </div>
        </CustomTableContainer>
    );
};

export default DashboardComponent;
import React, {useEffect, useRef, useState} from 'react';
import {Button, FormGroup, FormSelect} from '@snsw/react-component-library';
import {ButtonContainer, NotificationComponentHeader} from './SendNotificationComponent.styled';
import apiService from './../../services/api-services';
import NotificationContentComponent from '../NotificationContentComponent/NotificationContentComponent';
import Heading from '@snsw/react-component-library/build/Components/Headings/Heading';
import SendToComponent from '../SendToComponent/SendToComponent';
import {Link} from 'react-router-dom';
import apiServices from './../../services/api-services';
import {AutoSuggest} from '@snsw/react-component-library/build/Components';
import InPageAlert from '@snsw/react-component-library/build/Components/InPageAlert/InPageAlert';
import {environments, validEmail, validMobile} from '../../constants/applicationConstants';
import {scrollToTop} from '../GlobalStateComponent/GlobalState';
import HorizontalRule from '@snsw/react-component-library/build/HorizontalRule/hr.styled';
import {ContentSubHeader} from '../SendToComponent/SendToComponent.styled';
import {PortalFormContainer} from '../CommonComponents/CustomComponents/CustomComponents.styled';

const SendNotificationComponent = () => {
    const [notificationRequest, setNotificationRequest] = useState({
        notificationCode: '',
        customerId: '',
        customerIds: [],
        emails: [],
        mobileNumbers: [],
        email: {to: '', subject: '', content: ''},
        sms: {to: '', content: ''},
        web: {title: '', content: ''},
        push: {title: '', content: ''},
        notificationDetails: { data: [] },
    });
    const [alertBanner, setAlertBanner] = useState({
        visible: false,
        title: '',
        description: '',
        variant: 'info'
    });
    const [environment, setEnvironment] = useState('SRC');
    const [apiVersion, setApiVersion] = useState('V1');
    const [batchNotification, setBatchNotification] = useState(false);
    const [inputErrors, setInputErrors] = useState({
        notificationCode: {hasError: false, errorMessage: 'Invalid Notification Code'},
        webSubject: {hasError: false, errorMessage: 'Invalid web subject'},
        emailSubject: {hasError: false, errorMessage: 'Please provide an email subject or remove the email content'},
        emailContent: {hasError: false, errorMessage: 'Please provide email content or remove the email subject'},
        emailRecipient: {hasError: false, errorMessage: 'Invalid email address'},
        smsContent: {hasError: false, errorMessage: 'Invalid SMS content'},
        pushContent: {hasError: false, errorMessage: 'Invalid Push content'},
        pushTitle: {hasError: false, errorMessage: 'Invalid Push title'},
        smsRecipient: {hasError: false, errorMessage: 'Invalid Mobile number'},
        customerId: {hasError: false, errorMessage: 'Invalid customerId'},
        customerIds: {hasError: false, errorMessage: 'Invalid customerIds'},
        emails: {hasError: false, errorMessage: 'Invalid emails'},
        mobileNumbers: {hasError: false, errorMessage: 'Invalid mobile numbers'},
        inboxVariable: {hasError: false, errorMessage: 'You must provide a value for this variable'},
        inboxTitle: {hasError: false, errorMessage: 'Please provide an App title'},
        inboxContent: {hasError: false, errorMessage: 'Please provide a Notification title'},
    });
    const htmlValidation = useRef(true);
    // const [fetchNotificationsMap, setFetchNotificationsMap] = useState(true);
    const [notificationsServiceMap, setNotificationsServiceMap] = useState({});
    const [notificationsConfig, setNotificationsConfig] = useState({});
    const [optionalVariables, setOptionalVariables] = useState([]);
    const environmentOptions = [
        { value: 'SRC', text: process.env.REACT_APP_ENV_SRC },
        { value: 'DST', text: process.env.REACT_APP_ENV_DST },
    ];
    const apiOptions = [
        { text: 'Version 1', value: 'V1' },
        { text: 'Version 2', value: 'V2' }
    ];

    useEffect(() => {
        scrollToTop();
    }, [environment]);
    useEffect(() => {
        const errorElement = document.querySelectorAll('span[class^=\'FormError\']')[0]?.previousSibling;
        if (errorElement) {
            errorElement.scrollIntoView({
                alignToTop: true,
                behavior: 'smooth',
                block: 'center',
            });
        }
    }, [inputErrors]);

    const validateRequestV2 = () => {
        let valid = true;
        const {
            notificationCode,
            customerId
        } = notificationRequest;
        if (notificationCode.trim().length === 0) {
            setInputErrors(prevState => ({
                ...prevState,
                notificationCode: {hasError: true, errorMessage: 'You must provide a Notification Code'},
            }));
            valid = false;
        }
        if (customerId.trim().length === 0) {
            setInputErrors(prevState => ({
                ...prevState,
                customerId: {hasError: true, errorMessage: 'You must provide a recipient'},
            }));
            valid = false;
        }
        return valid;
    };
    const validateRequest = () => {
        let valid = true;
        const {
            notificationCode,
            customerId,
            email: { subject: emailSubject, content: emailContent, to: emailRecipient },
            web: { title: inboxTitle, content: inboxContent},
            push: { title: pushTitle, content: pushContent},
            sms: { to: smsRecipient, content: smsContent },
            emails: { length: emailRecipientsLength },
            customerIds: { length: customerIdRecipientsLength },
            mobileNumbers: { length: mobileNumberRecipientsLength },
            notificationDetails: { data: inboxVariables }
        } = notificationRequest;
        if (notificationCode.trim().length === 0) {
            setInputErrors(prevState => ({
                ...prevState,
                notificationCode: {hasError: true, errorMessage: 'You must provide a Notification Code'},
            }));
            valid = false;
        }
        if (emailSubject.trim().length > 0 && emailContent.trim().length === 0) {
            setInputErrors(prevState => ({
                ...prevState,
                emailContent: {hasError: true, errorMessage: 'Please provide email content or remove the email subject'},
            }));
            valid = false;
        }
        if (emailContent.trim().length > 0 && emailSubject.trim().length === 0) {
            setInputErrors(prevState => ({
                ...prevState,
                emailSubject: {hasError: true, errorMessage: 'Please provide an email subject or remove the email content'},
            }));
            valid = false;
        }
        if (emailContent.trim().length > 0 && emailSubject.trim().length >0 && !htmlValidation.current) {
            setInputErrors(prevState => ({
                ...prevState,
                emailContent: {hasError: true, errorMessage: 'Validate content to ensure HTML content is sanitised and secure'},
            }));
            valid = false;
        }
        if (inboxTitle.trim().length === 0 &&
            (inboxContent.trim().length > 0 || inboxVariables.find(variable => variable.value.trim().length > 0) !== undefined)
        ) {
            setInputErrors(prevState => ({
                ...prevState,
                inboxTitle: {hasError: true, errorMessage: 'Please provide an App title'},
            }));
        }
        if (inboxContent.trim().length === 0 &&
            (inboxTitle.trim().length > 0 || inboxVariables.find(variable => variable.value.trim().length > 0) !== undefined)
        ) {
            setInputErrors(prevState => ({
                ...prevState,
                inboxContent: {hasError: true, errorMessage: 'Please provide a Notification title'},
            }));
        }
        if (inboxVariables.find(variable => !optionalVariables.includes(variable.name) && variable.value.trim().length === 0) !== undefined
            && (inboxTitle.trim().length > 0 || inboxContent.trim().length > 0 ||
                inboxVariables.find(variable => variable.value.trim().length > 0) !== undefined)
        ) {
            setInputErrors(prevState => ({
                ...prevState,
                inboxVariable: {hasError: true, errorMessage: 'You must provide a value for this variable'},
            }));
        } else {
            setInputErrors(prevState => ({
                ...prevState,
                inboxVariable: {hasError: false, errorMessage: 'You must provide a value for this variable'},
            }));
        }
        if (pushContent.trim().length === 0 && pushTitle.trim().length > 0) {
            setInputErrors(prevState => ({
                ...prevState,
                pushContent: {hasError: true, errorMessage: 'Please provide a push content'},
            }));
        }
        if (!notificationsConfig.sendSmsEnabled && !notificationsConfig.sendEmailEnabled
            && !notificationsConfig.sendPushEnabled && !notificationsConfig.showInInbox
        ) { valid = false; }
        if (inboxTitle.trim().length === 0 && inboxContent.trim().length === 0 &&
            pushTitle.trim().length === 0 && pushContent.trim().length === 0 &&
            emailSubject.trim().length === 0 && emailContent.trim().length === 0 && smsContent.trim().length === 0
        ) { valid = false; }
        if (batchNotification) {
            const invalidMobileNumbers = notificationRequest.mobileNumbers.some(mobile => !validMobile.test(mobile));
            if (mobileNumberRecipientsLength > 0 && invalidMobileNumbers) {
                setInputErrors(prevState => ({
                    ...prevState,
                    mobileNumbers: {hasError: true, errorMessage: 'Invalid Mobile number must be in format +614...'},
                }));
                valid = false;
            }
            if (emailRecipientsLength === 0 && customerIdRecipientsLength === 0 && mobileNumberRecipientsLength === 0) {
                setInputErrors(prevState => ({
                    ...prevState,
                    customerIds: {hasError: true, errorMessage: 'You must provide at least one recipient'},
                    emails: {hasError: true, errorMessage: 'You must provide at least one recipient'},
                    mobileNumbers: {hasError: true, errorMessage: 'You must provide at least one recipient'},
                }));
                valid = false;
            }
            if ((emailRecipientsLength + customerIdRecipientsLength + mobileNumberRecipientsLength) > 10) {
                setInputErrors(prevState => ({
                    ...prevState,
                    ...((customerIdRecipientsLength > 0) ? {
                        customerIds: {hasError: true, errorMessage: 'You can only send a test notification to max 10 recipients'},
                    } : {}),
                    ...((emailRecipientsLength > 0) ? {
                        emails: {hasError: true, errorMessage: 'You can only send a test notification to max 10 recipients'},
                    } : {}),
                    ...((mobileNumberRecipientsLength > 0) ? {
                        mobileNumbers: {hasError: true, errorMessage: 'You can only send a test notification to max 10 recipients'}
                    } : {}),
                }));
                valid = false;
            }
        } else {
            /* if (customerId.trim().length > 0 &&
                notificationsConfig.service?.linkingServiceId == null &&
                !validInternalCustomerID.test(customerId)
            ) {
                setInputErrors(prevState => ({
                    ...prevState,
                    customerId: {hasError: true, errorMessage: 'Invalid Internal customerId'},
                }));
                valid = false;
            } */
            if (smsRecipient.trim().length > 0 && !validMobile.test(smsRecipient)) {
                setInputErrors(prevState => ({
                    ...prevState,
                    smsRecipient: {hasError: true, errorMessage: 'Invalid Mobile number must be in format +614...'},
                }));
                valid = false;
            }
            if (emailRecipient.trim().length > 0 && !validEmail.test(emailRecipient)) {
                setInputErrors(prevState => ({
                    ...prevState,
                    emailRecipient: {hasError: true, errorMessage: 'Invalid email address format'},
                }));
                valid = false;
            }
            if (customerId.trim().length === 0 && emailRecipient.trim().length === 0 && smsRecipient.trim().length === 0) {
                setInputErrors(prevState => ({
                    ...prevState,
                    smsRecipient: {hasError: true, errorMessage: 'You must provide at least one recipient'},
                    customerId: {hasError: true, errorMessage: 'You must provide at least one recipient'},
                    emailRecipient: {hasError: true, errorMessage: 'You must provide at least one recipient'},
                }));
                valid = false;
            }
            if (emailRecipient.trim().length > 0 && smsRecipient.trim().length > 0) {
                setInputErrors(prevState => ({
                    ...prevState,
                    smsRecipient: {hasError: true, errorMessage: 'You can not send to a guest customer across two channels'},
                    emailRecipient: {hasError: true, errorMessage: 'You can not send to a guest customer across two channels'},
                }));
                valid = false;
            }
        }
        return valid;
    };

    const resetForm = () => {
        window.scrollTo(0, 0);
        setNotificationRequest({
            notificationCode: '',
            customerId: '',
            customerIds: [],
            emails: [],
            mobileNumbers: [],
            email: {to: '', subject: '', content: ''},
            sms: {to: '', content: ''},
            web: {title: '', content: ''},
            push: {title: '', content: ''},
            notificationDetails: { data: [] },
        });
        setEnvironment('SRC');
        setBatchNotification(false);
        setNotificationsConfig({});
    };

    const sendNotificationV1 = () => {
        if (validateRequest()) {
            if (batchNotification) {
                apiService.postBatchNotification(notificationRequest, environment)
                    .then((response) => {
                        resetForm();
                        setAlertBanner({
                            variant: 'success',
                            title: 'Successfully sent Batch Notification',
                            description: `batchNotificationId: ${response.data.batchNotificationId}`,
                            visible: true
                        });
                    }, (e) => {
                        window.scrollTo(0, 0);
                        if (e.response.data.errorCode === 'SND_NOT_BATCH') {
                            setAlertBanner(prevState => ({
                                variant: 'error',
                                title: 'Unable to process your request',
                                description: e.response.data.message,
                                visible: true
                            }));
                        } else {
                            setAlertBanner(prevState => ({
                                variant: 'error',
                                title: 'Unable to process your request',
                                description: 'An error occurred while processing your request',
                                visible: true
                            }));
                        }
                    });
            } else {
                apiService.postSingleNotification(notificationRequest, environment)
                    .then((response) => {
                        resetForm();
                        setAlertBanner({
                            variant: 'success',
                            title: 'Successfully sent Notification',
                            description: `notificationId: ${response.data.notificationId}`,
                            visible: true
                        });
                    })
                    .catch((e) => {
                        window.scrollTo(0, 0);
                        if (e.response.data.errorCode === 'SND_SINGLE_NOT') {
                            setAlertBanner(prevState => ({
                                variant: 'error',
                                title: 'Unable to process your request',
                                description: e.response.data.message,
                                visible: true
                            }));
                        } else {
                            console.log(e);
                            setAlertBanner(prevState => ({
                                variant: 'error',
                                title: 'Unable to process your request',
                                description: 'An error occurred while processing your request',
                                visible: true
                            }));
                        }
                    });
            }
        }
    };

    const sendNotificationV2 = () => {
        if (validateRequestV2()) {
            apiService.postSingleNotificationV2CustomerId(notificationRequest, environment)
                .then((response) => {
                    resetForm();
                    setAlertBanner({
                        variant: 'success',
                        title: 'Successfully sent Notification',
                        description: `notificationId: ${response.data.data.notificationId}`,
                        visible: true
                    });
                })
                .catch((e) => {
                    window.scrollTo(0, 0);
                    if (e.response.data.errorCode === 'SND_SINGLE_NOT') {
                        setAlertBanner(prevState => ({
                            variant: 'error',
                            title: 'Unable to process your request',
                            description: e.response.data.message,
                            visible: true
                        }));
                    } else {
                        console.log(e);
                        setAlertBanner(prevState => ({
                            variant: 'error',
                            title: 'Unable to process your request',
                            description: 'An error occurred while processing your request',
                            visible: true
                        }));
                    }
                });
        }
    };

    const sendNotification = () => {
        if (apiVersion === 'V1') {
            sendNotificationV1();
        } else if (apiVersion === 'V2') {
            sendNotificationV2();
        }
    };

    useEffect(() => {
        setNotificationsServiceMap({});
        apiServices.getNotificationsServiceMap(null, environment).then(map => {
            setNotificationsServiceMap(map);
        })
            .catch(error => console.log(error.message));
        if (inputErrors.notificationCode.hasError) {
            setInputErrors(prevState => ({
                ...prevState,
                notificationCode: {hasError: false, errorMessage: 'Invalid Notification Code'}
            }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [environment]);

    const onSelect = (suggestion, value) => {
        setNotificationRequest(prevState => ({
            ...prevState,
            notificationCode: value
        }));
        if (inputErrors.notificationCode.hasError) {
            setInputErrors(prevState => ({
                ...prevState,
                notificationCode: {hasError: false, errorMessage: 'You must provide a Notification Code'},
            }));
        }
        // Only makes call if input field has a value
        if (value) {
            apiServices.searchNotificationCode(value, environment === 'DST')
                .then(response => {
                    setNotificationsConfig(response);
                    if (!(response.sendEmailEnabled || response.sendSmsEnabled || response.showInInbox)) {
                        setInputErrors(prevState => ({
                            ...prevState,
                            notificationCode: {
                                hasError: true,
                                errorMessage: 'Notification Code has no enabled channels'
                            },
                        }));
                    }
                    if (response.showInInbox) {
                        apiServices.getContentsByNotificationId(response.id, 'INBOX', environment === environments.DST)
                            .then(contents => {
                                const notificationInboxContent = contents.find(content => content.type === 'CONTENT');
                                if (notificationInboxContent !== undefined) {
                                    setNotificationsConfig(prevState => ({
                                        ...prevState,
                                        inboxContent: notificationInboxContent.content
                                    }));
                                }
                            })
                            .catch((e) => {
                            // setInputErrors(prevState => ({
                            //     ...prevState,
                            //     notificationCode: {hasError: true, errorMessage: 'Notification code not found'},
                            //     }));
                            });
                    }
                })
                .catch((e) => {
                    setInputErrors(prevState => ({
                        ...prevState,
                        notificationCode: {hasError: true, errorMessage: 'Notification code not found'},
                    }));
                });
        }
    };

    const changeEnvironment = (event) => {
        setEnvironment(event.target.value);
        setNotificationRequest(prevState => ({
            ...prevState,
            email: {to: '', subject: '', content: ''},
            sms: {to: '', content: ''},
            web: {title: '', content: ''},
            push: {title: '', content: ''},
            notificationDetails: { data: [] },
            notificationCode: ''
        }));
        setNotificationsConfig({});
    };

    const changeApiVersion = (event) => {
        setApiVersion(event.target.value);
    };

    return (
        <PortalFormContainer>
            <Button variant='back' as={ Link } href='/'>Back</Button >
            <NotificationComponentHeader
                data-test='send-notification-header'
                id='sendHeader'>
                <Heading
                    level={ 2 }
                    style={ {
                        height: 'fit-content',
                        margin: '6px 0 25px 0'
                    } }>
                    Send a test
                </Heading>
            </NotificationComponentHeader>
            {alertBanner.visible &&
                <InPageAlert id='test-notification-page-alert' variant = { alertBanner.variant } title={ alertBanner.title }>
                    <p data-test='notification-sub-heading'>{alertBanner.description}</p>
                </InPageAlert>
            }
            <Heading data-testid='select-notification-header' level={ 3 } style={ {height: 'fit-content'} }>
                1. Select notification
            </Heading>
            <ContentSubHeader>Version 2 will use the content stored against the notification code in the portal.
            Version 1 notifications will need content added below.</ContentSubHeader>
            <FormGroup
                id='notification-code-input'
                label='Notification code'

                hasError={ inputErrors.notificationCode.hasError }
                errorMessage={ inputErrors.notificationCode.errorMessage }
                margin={ { top: 'lg' } }
            >
                <AutoSuggest
                    data-test='notification-code-input'
                    id='notification-search-id'
                    name='notificationCode'
                    suggestions={ Object.keys(notificationsServiceMap) }
                    onSelect={ onSelect }
                    onBlur={ onSelect }
                    inputWidth={ 'xl' }
                    placeholder={ 'Search' }
                    margin={ { top: 'lg' } }
                    value={ notificationRequest.notificationCode }
                />
            </FormGroup>

            <FormSelect
                label='API version'
                name='apiVersion'
                onChange={ changeApiVersion }
                value={ apiVersion }
                options={ apiOptions }
                inputWidth={ 'xl' }
            />

            <FormSelect
                label='Testing environment'
                name='environment'
                onChange={ changeEnvironment }
                value={ environment }
                options={ environmentOptions }
                inputWidth={ 'xl' }
            />
            <HorizontalRule/>
            { apiVersion === 'V1' &&
                <NotificationContentComponent
                    notificationRequest={ notificationRequest }
                    setNotificationRequest={ setNotificationRequest }
                    inputErrors={ inputErrors }
                    notificationsConfig={ notificationsConfig }
                    setInputErrors={ setInputErrors }
                    setOptionalVariables={ setOptionalVariables }
                    optionalVariables={ optionalVariables }
                    htmlValidation={ htmlValidation }
                />
            }
            <SendToComponent
                notificationRequest={ notificationRequest }
                setNotificationRequest={ setNotificationRequest }
                setBatchNotification={ setBatchNotification }
                inputErrors={ inputErrors }
                setInputErrors={ setInputErrors }
                apiVersion={ apiVersion }
                environment={ environment }
            />
            <ButtonContainer>
                <Button
                    data-testid='send-notification-btn'
                    onClick={ sendNotification }
                    variant='primary'
                    id='sendNotificationBtn'
                >{ batchNotification ? 'Send notifications' : 'Send notification' }
                </Button>
            </ButtonContainer>

        </PortalFormContainer>
    );
};

export default SendNotificationComponent;

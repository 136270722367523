import styled, {css} from 'styled-components';
import {Heading, TableCell, TableHeader, TableRow} from '@snsw/react-component-library';
import {Label} from '@snsw/react-component-library/build/Accordion/Label';
import {ToggleButton} from '@mui/material';
import {
    boxShadowStyle,
    colors,
    fontSize,
    fontWeight,
    globalFont, lineHeight
} from '../CommonComponents/Typography/Typography.styled';

export const columnWidths = {
    notification: '262px',
    code: '130px',
    service: '180px',
    agency: '140px',
    channels: '178px',
    updated: '94px',
    status: '97px',
    actionButton: '35px'
};

export const TH = styled(TableHeader)`
    //styleName: Para - Semi;
    font-family: ${globalFont};
    font-size: ${fontSize.h6};
    font-weight: ${fontWeight.semibold500};
    line-height: ${lineHeight.bodyXS};
    text-align: left;
    color: ${colors.brand.snswPrimaryBlue};
    padding-top: unset;
    padding-bottom: unset;
`;

export const TR = styled(TableRow)`
    height: 41px;
`;

export const DashboardHeading = styled(Heading)`
    //styleName: H1;
    font-family: ${globalFont};
    font-size: 28px;
    font-weight: ${fontWeight.bold};
    color: #002664;
    line-height: 36.4px;
    text-align: left;
    margin: unset;
    padding-bottom: 8px;

`;

export const TextCell = styled(TableCell)`
    //styleName: Para - Regular;
    font-family: ${globalFont};
    font-size: ${fontSize.body};
    font-weight: ${fontWeight.regular};
    line-height: ${lineHeight.bodyXXS};
    text-align: left;
    color: ${colors.grey.shuttle};
    padding-top: unset;
    padding-bottom: unset;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    background-color: white;
`;

export const Cell = styled(TableCell)`
    //styleName: Para - Regular;
    font-family: ${globalFont};
    font-size: ${fontSize.body};
    font-weight: ${fontWeight.regular};
    line-height: ${lineHeight.bodyXXS};
    text-align: left;
    color: ${colors.grey.shuttle};
    padding-top: unset;
    padding-bottom: unset;
    background-color: white;
    table-layout: fixed;
    overflow: hidden;
`;

export const StatusLabelStyle = css`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 6px;
    border-radius: 3px;
    //margin: 6px;
    height: ${lineHeight.h5};
`;

export const LiveStatusLabel = styled(Label)`
    /* Auto layout */
    ${StatusLabelStyle};
    width: 40px;
    left: 20px;
    top: 20px;

    /* Notification colours/SNSW Success Green Light #E5F6E6 */
    background: ${colors.status.successGreen.background};
    color: ${colors.status.successGreen.dark};
    padding-left: 25px;
    padding-right: 25px;
`;

export const InReviewStatusLabel = styled(Label)`
    /* Auto layout */
    ${StatusLabelStyle};
    width: 80px;
    left: 20px;
    top: 92px;

    /* Notification colours/SNSW Warning Orange Light #FCEFE6 */
    background: ${colors.status.warningOrange.background};
    color: ${colors.status.warningOrange.dark};
`;
export const SentStatusLabel = styled(Label)`
    /* Auto layout */
    ${StatusLabelStyle};
    width: 40px;
    left: 20px;
    top: 20px;

    background: ${colors.grey.geyser};
    color: ${colors.brand.snswDarkGrey};
    padding-left: 25px;
    padding-right: 25px;
`;
export const ArchivedStatusLabel = styled(Label)`
    /* Auto layout */
    ${StatusLabelStyle};
    width: 58px;
    left: 20px;
    top: 92px;

    background: ${colors.grey.geyser};
    color: ${colors.grey.shuttle};
`;
export const UnknownStatusLabel = styled(Label)`
    /* Auto layout */
    ${StatusLabelStyle};
    width: 58px;
    left: 20px;
    top: 92px;

    background: #e71936;
    color: #edeef1;
`;

export const FilterButton = styled(ToggleButton)`
    /* Button */
    box-sizing: border-box;

    /* Auto layout */
    gap: 8px;
    padding: 14px 16px;
    width: 115px;
    height: 40px;

    /* Grey colours/SNSW White #FFFFFF */
    background: #FFFFFF !important;
    border-width: 1.5px !important;
    border-style: solid;
    border-color: ${colors.grey.geyser};

    &:focus {
        border-color: ${colors.grey.neutral200};
        box-shadow: ${boxShadowStyle};
    }

    /* Inside auto layout */
    flex: none;
    flex-grow: 0;

    /* Label */
    font-family: ${globalFont};
    font-weight: ${fontWeight.semibold500};
    font-size: ${fontSize.body} !important;
    text-align: center;
    text-transform: unset !important;
`;
